<template>
  <div
    v-if="opened"
    class="editor-items"
  >
    <div class="editor-items-list">
      <h3>Konfigurieren</h3>

      <p>
        Wähle weitere Produkte aus und füge sie so deinem individuellen Angebot
        hinzu.
      </p>

      <p>{{ OfferingSet }}</p>

      <div class="offered-item-list">
        <ul>
          <OfferedItem
            v-for="(offerings, index) in offerings"
            :key="index"
            :offerings="offerings"
          />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import OfferedItem from "../generator/OfferedItem.vue";
export default {
  components: {
    OfferedItem,
  },
  data() {
    return {
      OfferingSet: "Text und Konzept",
    };
  },
  computed: {
    offerings() {
      return this.$store.state.offerings;
    },
    opened() {
      return this.$store.state.showEditorItems;
    }
  },
  methods: {
  },
};
</script>
