<template>
  <div class="client-modal">
    <div class="client-modal-gradient-top" />
    <img
      src="~@/assets/cross.svg"
      class="client-modal-close"
      @click="$emit('closeClientModal')"
    />
    <div class="client-modal-description">
      <div class="client-modal-header tablet">
        <h1>{{ clients.name }}</h1>
        <h3>{{ clients.jobDescription }}</h3>
      </div>
      <p>
        {{ clients.ownerBlurb }}
      </p>
      <h3 class="owner-name">
        {{ clients.ownerName }}
      </h3>
      <p class="owner-title">
        {{ clients.ownerTitle }}
      </p>
    </div>

    <div class="client-modal-account">
      <span>Services</span>
      <br />
      <p v-html="clients.accountDescription"></p>
    </div>
    <div class="client-modal-gradient-bottom" />
  </div>
</template>
<script>
export default {
  props: {
    clients: Object,
  },
  methods: {},
};
</script>
