<template>
  <div>
    <div class="row header-main mobile">
      <router-link
        to="/"
        tag="h1"
      >
        Wirklichkeitsdichte
      </router-link>
      <img
        class="menu-open"
        src="~@/assets/menu_1pt.svg"
        @click="isClosed = !isClosed"
      >
    </div>
    <nav
      class="navigation-mobile mobile"
      :class="{ closed: isClosed }"
    >
      <router-link
        to="/services"
        tag="div"
        class="menu-item-mobile"
        exact-active-class="active-mobile"
        @click="isClosed = true"
      >
        Services
      </router-link>

      <router-link
        to="/generator"
        tag="div"
        class="menu-item-mobile"
        exact-active-class="active-mobile"
        @click="isClosed = true"
      >
        Angebotsgenerator
      </router-link>

      <router-link
        to="/team"
        tag="div"
        class="menu-item-mobile"
        exact-active-class="active-mobile"
        @click="isClosed = true"
      >
        Team
      </router-link>

      <router-link
        to="/clients"
        tag="div"
        class="menu-item-mobile"
        exact-active-class="active-mobile"
        @click="isClosed = true"
      >
        Clients
      </router-link>

      <router-link
        to="/contact"
        tag="div"
        class="menu-item-mobile"
        exact-active-class="active-mobile"
        @click="isClosed = true"
      >
        Kontakt
      </router-link>
    </nav>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isClosed: true,
    };
  },
};
</script>
