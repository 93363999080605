<template>
  <div
    class="column header-main large"
    @click="refresh()"
  >
    <img
      title="Wirklichkeitsdichte Logo"
      src="~@/assets/Wirklichkeitsdichte-agentur-text-b2b-content-strategie.svg"
      alt="Wirklichkeitsdichte – Agentur für Text und B2B Content Strategien"
    >
    <h1>WIRKLICHKEITSDICHTE</h1>
  </div>
</template>

<script>
export default {
  computed: {},
  methods: {
    refresh() {
      this.$router.push('/');
      this.$router.go();
    },
  },
};
</script>
