import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from 'vue-meta'
//import Landing from "../views/Landing.vue"
// import Legal from "../views/Legal.vue";
import Offer from "../views/Services.vue";
// import About from "../views/About.vue";

import Scroller from "../views/Scroller.vue";
// import NotFound from "../views/NotFound.vue";
// const Scroller = () => import("../views/Scroller.vue");
//const Landing = () => import("../views/Landing.vue");

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: "/",
    name: "Home",
    // component: Scroller,
    component: Scroller,
    alias: "/home",
  },
  {
    path: "/services",
    name: "Services",
    component: Offer,
  },

  {
    path: "/about",
    name: "About",
    // component: About,
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/legal",
    name: "Legal",
    // component: Legal,
    component: () => import(/* webpackChunkName: "legal" */ "../views/Legal.vue"),
  },
  {
    path: "/:catchAll(.*)*",
    name: "NotFound",
    // component: NotFound,
    component: () => import(/* webpackChunkName: "app-home" */ "../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  base: process.env.BASE_URL,
  routes,
});

export default router;
