<template>
  <li
    :class="{ 'offered-item-list-checked': checked }"
    @click="addItem(offerings.id)"
  >
    <img
      class="item-arrow"
      src="~@/assets/leftarrow_1pt.svg"
    >
    <p>{{ offerings.name }}</p>
    <img
      class="item-cross"
      src="~@/assets/cross_white.svg"
    >
  </li>
</template>
<script>
export default {
  name: "OfferedItemMobile",
  props: {
    offerings: Object,
  },
  computed: {
    checked() {
      let status = this.$store.getters.getOfferingById(this.offerings.id);
      return status.chosen;
    },
  },
  methods: {
    setInfo(index) {
      this.$store.commit("setInfo", index);
      this.$store.commit("toggleDetailModal");
    },
    addItem(index) {
      if (this.checked === false) {
        this.$store.commit("addToList", index);

        // open detail modal to adjust quantity
        this.setInfo(index);
      } else {
        this.$store.commit("deleteFromList", index);
        this.$store.commit("resetQuantity", index);
      }
    },
  },
};
</script>
