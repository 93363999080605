<template>
  <div
    id="editor"
    class="offering-edit-container"
  >
    <div class="offering-header large">
      <h2>{{ offeringHeader }}</h2>
      <span>Experience</span>
      <div
        class="close-editor-mobile"
        @click="$emit('close-generator')"
      >
        <img src="~@/assets/cross.svg">
      </div>
    </div>
    <div class="offering-header-mobile mobile">
      <div class="offering-price-mobile column">
        <div class="offering-edit-name row">
          <h2>Copywriter</h2>
        </div>
        <span>Experience</span>
      </div>
    </div>

    <div class="offering-price-top-container">
      <div id="prices">
        <div class="offering-price row">
          <span>Basispreis</span>
          <span class="base-price">{{ toCurrencyString(price) }} €</span>
        </div>
        <div class="offering-price row" v-if="discount != 0">
          <span>Rabatt</span>
          <span class="base-price-invert">{{ toCurrencyString(discount) }} €</span>
        </div>
        <div class="offering-price row" v-if="discount != 0">
          <span>finaler Preis</span>
          <span class="base-price">{{ toCurrencyString(price-discount) }} €</span>
        </div>
      </div>
    </div>

    <div class="item-list">
      <ul>
        <ChosenOffering
          v-for="(offerings, index) in offerings"
          :key="index"
          :offerings="offerings"
          @delete="deleteFromList(offerings.id)"
          @increment="incrementOffering(offerings.id)"
          @decrement="decrementOffering(offerings.id)"
          @setInfo="setInfo(offerings.id)"
        />
      </ul>
    </div>
    <div class="add-item-plus">
      <img
        src="~@/assets/plus_circle.svg"
        @click="toggleMobileItemList()"
      >
    </div>

    <CallToAction
      :offering-header="offeringHeader"
      :offerings-list="offerings"
      :price="price"
      :discount="discount"
    />
  </div>
</template>
<script>
import ChosenOffering from "../generator/ChosenOffering.vue";
import CallToAction from "./CallToAction.vue";

import editorLogicMixin from "../mixins/editorLogicMixin.js"

export default {
  components: {
    ChosenOffering,
    CallToAction,
  },

  mixins:[editorLogicMixin],

}
</script>
