<template>
  <div class="offering-column">
    <h1 class="desktop">
      {{ services.name }}
    </h1>
    <div>
      <img
        :src="require(`@/assets/services/${services.icon}`)"
        class="offering-column-illustration desktop"
      >
    </div>
    <div>
      <h1 class="tablet">
        {{ services.name }}
      </h1>
      <div class="tablet-offering-description">
        <div>
          <img
            :src="require(`@/assets/services/${services.icon}`)"
            :alt="altText"
            :title="titleText"
            class="offering-column-illustration tablet"
          >
        </div>

        <div>
          <p>
            {{ services.description }}
          </p>
        </div>
      </div>
      <div
        class="offering-column-button"
        @click="goTarget"
      >
        {{ services.button }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    services: Object,
  },
  computed: {
    altText() {
      return this.services.alt;
    },
     titleText() {
      return this.services.title;
    }
  },
  methods: {
    goTarget() {
      window.location.href = this.services.target;
    },
  },
};
</script>
