<template>
  <div
    class="detail-modal-container"
    :class="{ 'detail-modal-close': opened === false }"
  >
    <div class="detail-modal-container-backdrop" @click="toggleDetailModal()" />
    <div
      class="detail-modal"
      :class="{ 'detail-modal-close': opened === false }"
    >
      <div class="detail-modal-top">
        <div class="detail-modal-close-button" @click="toggleDetailModal()">
          <img src="~@/assets/cross_white.svg" />
        </div>
        <div class="detail-modal-counter">
          <img
            src="~@/assets/leftarrow_1pt_white.svg"
            @click="decrementOffering(info.id)"
          />

          <input
            id="quantity"
            class="item-amount"
            type="number"
            name="quantity"
            min="1"
            max="99"
            step="1"
            pattern="/d+"
            :value="info.quantity"
            @input="setOffering(info.id, $event.target.value)"
            onClick="this.select()"
            @keyup.enter="toggleDetailModal()"
          />
          <img
            src="~@/assets/rightarrow_1pt_white.svg"
            @click="incrementOffering(info.id)"
          />
        </div>
        <div class="detail-modal-header">
          <h3>{{ info.name }}</h3>
        </div>

        <div class="detail-modal-confirm-button" @click="toggleDetailModal()">
          Übernehmen
        </div>
      </div>

      <div class="detail-modal-bottom">
        <p>
          {{ info.info.description }}
          <br />
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DetailModal",
  data() {
    return {};
  },
  computed: {
    opened() {
      console.dir(this.$store.state.showDetailModal);
      return this.$store.state.showDetailModal;
    },
    info() {
      if (this.$store.state.shownInfo) {
        return this.$store.state.shownInfo;
      } else return null;
    },
  },
  methods: {
    toggleDetailModal() {
      this.$store.commit("toggleDetailModal");
    },
    incrementOffering(index) {
      // console.log("index: " + index);
      this.$store.commit("addQuantity", index);
    },
    decrementOffering(index) {
      // console.log("index: " + index);
      this.$store.commit("substractQuantity", index);
    },

    filterValue(value) {
      const oldValue = this.info.quantity; // cache initial value
      let testValue = RegExp("^[1-9][0-9]{0,2}$"); // only positive integers between 1 and 999
      // console.log(testValue.test(value));
      if (testValue.test(value)) {
        return value;
      } else return oldValue; // return old value if input fails regexp check
    },
    setOffering(index, value) {
      console.log(value);
      let filteredValue = this.filterValue(value);
      console.log("filteredValue: " + filteredValue);
      let payload = { id: index, amount: filteredValue };
      this.$store.commit("enterQuantity", payload);
    },
  },
};
</script>
