<template>
  <div
    class="cookie-banner"
    :class="{ 'cookie-banner-accepted': (accepted === true) || (cookies_accepted) }" 
  >
    <h1>Cookies</h1>
    <p>
      Wir verwenden nur notwendige Cookies und ausschließlich bei unserem
      Angebotsgenerator.
    </p>
    <a @click="accept()">Akzeptieren</a>
  </div>
</template>

<script>
export default {
  name: "CookieBanner",
  components: {},
  data() {
    return {
      cookies_accepted: localStorage.accept_cookies,
    };
  },
  computed: {
      accepted(){
          return (this.$store.getters.cookiesAccepted);
      },
      visited(){
        console.log(localStorage.accept_cookies);
          return localStorage.accept_cookies;
      }

  },
  methods: {
      accept() {
          this.$store.commit("acceptCookies");
      }
  }
};
</script>
