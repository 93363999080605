export default {
    data() {
      return {
        //offerings: this.$store.getters.getChosenOfferings,
      };
    },
    computed: {
      opened() {
        return this.$store.state.showItemsMobile;
      },
      offerings() {
        return this.$store.getters.getChosenOfferings;
      },
      price() {
        return this.calculatePrice();
      },
      discount() {
        return this.calculateDiscount();
      },
      offeringHeader() {
        return this.$store.getters.getchosenCarrousselColumn;
      },
    },
    created() {
      let center = document.getElementById("editor");
      center.scrollIntoView({
        behavior: "smooth",
        inline: "start",
        block: "start",
      });
    },
    methods: {
      toCurrencyString(number) {
        return new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          .format(number)
          .toString();
      },
      toggleMobileItemList() {
        this.$store.commit("toggleMobileList");
      },
      deleteFromList(index) {
        this.$store.commit("deleteFromList", index);
        this.$store.commit("resetQuantity", index);
      },
      setInfo(index) {
        this.$store.commit("setInfo", index);
        this.$store.commit("toggleDetailModal");
      },
      incrementOffering(index) {
        this.$store.commit("addQuantity", index);
      },
      decrementOffering(index) {
        this.$store.commit("substractQuantity", index);
      },
      calculatePrice() {
        return this.offerings.reduce((total, offering) => {
          return total + offering.price * offering.quantity;
        }, 0);
      },
      calculateDiscount() {
        let discount = 0;
        let quantityList = {};
  
        this.offerings.map((offering) => {
          quantityList[offering.category] = offering.quantity;
        });
  
        // console.log(quantityList);
  
        // blogposts
        if (quantityList.blogpost) {
          if (quantityList.blogpost > 1 && quantityList.blogpost <= 3) {
            discount += quantityList.blogpost * 25;
          }
          if (quantityList.blogpost > 3 && quantityList.blogpost <= 5) {
            discount += quantityList.blogpost * 50;
          }
          if (quantityList.blogpost > 5 && quantityList.blogpost <= 8) {
            discount += quantityList.blogpost * 75;
          }
          if (quantityList.blogpost > 8) {
            discount += quantityList.blogpost * 100;
          }
        }
  
        // deep dive
        if (quantityList.deepdive) {
          if (quantityList.deepdive == 2) {
            discount += quantityList.deepdive * 50;
          }
          if (quantityList.deepdive == 3) {
            discount += quantityList.deepdive * 65;
          }
          if (quantityList.deepdive > 3 && quantityList.deepdive < 8) {
            discount += quantityList.deepdive * 100;
          }
          if (quantityList.deepdive >= 9) {
            discount += quantityList.deepdive * 125;
          }
        }
  
        // social post
        if (quantityList.socialpost) {
          
          if (quantityList.socialpost >= 5) {
            discount += Math.floor(quantityList.socialpost / 5 ) * 137.5;
           
          }
        }
  
        // story post
        if (quantityList.storypost) {
          if (quantityList.storypost >= 5 && quantityList.storypost < 10) {
            discount += quantityList.storypost * 7.5;
          }
          if (quantityList.storypost >= 10) {
            discount += quantityList.storypost * 10;
          }
        }
  
        // consulting has no discount
      
        // pagetext
        if (quantityList.pagetext) {
          if (quantityList.pagetext > 1 && quantityList.pagetext <= 3) {
            discount += quantityList.pagetext * 25;
          }
          if (quantityList.pagetext > 3 && quantityList.pagetext <= 5) {
            discount += quantityList.pagetext * 50;
          }
          if (quantityList.pagetext > 5 && quantityList.pagetext <= 8) {
            discount += quantityList.pagetext * 75;
          }

          if (quantityList.pagetext > 8) {
            discount += quantityList.pagetext * 100;
          }
        }
  
        // voice over
        if (quantityList.voiceover) {
          if (quantityList.voiceover >= 4) {
            discount += quantityList.voiceover * 50;
          }
        }
  
        // script erklärfilm
        if (quantityList.explainer) {
          if (quantityList.explainer == 4 || quantityList.explainer == 3) { 
            discount += quantityList.explainer * 75;
          }
          if (quantityList.explainer > 4 && quantityList.explainer <= 8) {
            discount += quantityList.explainer * 100;
          }
          if (quantityList.explainer > 8) {
            discount += quantityList.explainer * 125;
          }
        }
  
        
  
        // additional hours
        if (quantityList.hours) {
          if (quantityList.hours >= 10 && quantityList.hours < 20) {
            discount += quantityList.hours * 11.825;
          }
          if (quantityList.hours >= 20) {
            discount += quantityList.hours * 26.015;
          }
        }
        return discount;
      },
    },
  }