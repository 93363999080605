import Vue from "vue";
import Vuex from "vuex";
import acumin from "@/assets/AcuminProRegular.json";

import {
  Scene,
  TrackballControls,
  PerspectiveCamera,
  WebGLRenderer,
  Color,
  FogExp2,
  CylinderBufferGeometry,
  MeshPhongMaterial,
  Mesh,
  DirectionalLight,
  AmbientLight,
  LineBasicMaterial,
  Geometry,
  Vector3,
  //Quaternion,
  Line,
  FontLoader,
  TextGeometry,
  // GeometryUtils,
  // QuaternionKeyframeTrack,
  VectorKeyframeTrack,
  AnimationClip,
  AnimationMixer,
  EffectComposer,
  RenderPass,
  // BokehPass,
  // ShaderPass,
  // BokehShader
} from "three-full";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    width: 0,
    height: 0,
    camera: null,
    controls: null,
    scene: null,
    renderer: null,
    postprocessing: {},
    acumin: acumin,
    mixer: null,
    mixerO: null,
    mixerI: null,
    mixerN: null,
    mixerBang: null,
    axisLines: [],
    pyramids: [],
    letters: [],
    profiles: [
      {
        id: 1,
        imgurl: "peter-wagner-ceo-head-of-text.jpg",
        alt:"Peter Wagner – CEO und Head of Text – Wirklichkeitsdichte Agentur für Text und B2B Content",
        title:"peter-wagner-ceo-head-of-text",
        name: "Peter Wagner",
        mail: "peter.wagner@wirklichkeitsdichte.org",
        description:
          "M.A. Gesellschafts- und Wirtschaftskommunikation. Erfinder der Spielwirklichkeitsdichte. Schreibt seit 2014 für den schnöden Mammon, macht seitdem aber auch: Startups, Projektmanagement, Geschäftsführung, B2B-Content-Marketing – natürlich alles gleichzeitig und Vollzeit.",
        job: "Geschäftsführer",
        calling: "Autor, Kabarettist und Musiker",
      },

      {
        id: 2,
        imgurl: "hannes-wagner-senior-content-lead.jpg",
        title:"hannes-wagner-senior-content-lead",
        alt: "Hannes Wagner – Senior Lead Content – Wirklichkeitsdichte Agentur für Text und B2B Content Strategie",
        name: "Hannes Wagner",
        mail: "hannes.wagner@wirklichkeitsdichte.org",
        description:
          "M.F.A. Mediengestaltung und -produktion. Fulldome- und Erklärfilmexperte. Simplifiziert schwierige Sachverhalte seit 2010, macht seitdem aber auch: Projektmanagement, Skript, Kreative Leitung, Animationsfilm und Postproduktion. Leitet den Standort Weimar.",
        job: "Lead Creative",
        calling: "360°-Filmemacher, Fantasy-Autor, Agent für Kreative",
      },

      {
        id: 3,
        imgurl: "rebecca-irvine-founder-interne-kommunikation.jpg",
        alt:"Rebecca Irvine – Founder und interne Kommunikation - Wirklichkeitsdichte Agentur für Text und B2B Content",
        title:"rebecca-irvine-founder-interne-kommunikation",
        name: "Bec Irvine",
        mail: "rebecca.irvine@wirklichkeitsdichte.org",
        description:
          "B.A. Politics, Philosophy, German. Risikofreundin, Debattiermeisterin, Expertin für Gastfreundschaft. Seit 2011 spricht Rebecca mit Menschen und bringt ihnen die Kunst des Debattierens bei, hat ihre Leidenschaft für Craftbeer, Management und Gastfreundschaft zu einem Beruf gemacht.",
        job: "Lead Internal Affairs",
        calling: "Altruistin, DJ, Investorin",
      },

      {
        id: 4,
        imgurl: "philipp-lange-senior-art-lead-wirklichkeitsdichte.jpg",
        alt: "Philipp Lange – Senior Art – Wirklichkeitsdichte Agentur für Text und B2B Content Strategie",
        title: "philipp-lange-senior-art-lead-wirklichkeitsdichte",
        name: "Philipp Lange",
        mail: "philipp.lange@wirklichkeitsdichte.org",
        description:
          "B.A. Visuelle Kommunikation. Experte für Print- und Digitalpublikationen. Philipp arbeitet und experimentiert mit analogen, digitalen, visuellen sowie textlichen Medien, ist Texthandwerker und Detaildenker.",
        job: "Senior Grafikdesigner",
        calling: "Bildender Künstler",
      },

      {
        id: 5,
        imgurl: "sontje-liebner-senior-text-lead-wirklichkeitsdichte.jpg",
        alt:"Sontje Liebner – Senior Lead Text - Wirklichkeitsdichte Agentur für Text und B2B Content Strategie",
        title:"sontje-liebner-senior-text-lead-wirklichkeitsdichte",
        name: "Sontje Liebner",
        mail: "sontje.liebner@wirklichkeitsdichte.org",
        description:
          "B.A. Kunstgeschichte. Von der SEO-Redaktion zur Texterin und Projektmanagerin. 2017 quer eingestiegen, gerade dabeigeblieben. Bringt Wörter auf den Punkt. Schreibt und forscht rund um Kunst und Kultur. Intersektional und politisch. Spitzfindig und wortgewandt.",
        job: "Content Lead",
        calling:
          "Zwischen Wortakrobatik und Texthandwerk, Kreativität und Klartext, Fantasie und Strategie",
      },
    ],
    services: [
      {
        id: 1,
        name: "Text",
        description:
          "Wir bieten euch Copywriting, long und short, für Werbung, Social Media, Blogs und Magazine, sogar Ghostwriting für Fachartikel und Bücher. Wir schreiben tiefgehende Artikel, Whitepaper, sowie Skripte für Erklär- und Imagefilme und Voice-Overs. Übersetzen können wir auch: muttersprachlich in DE/EN/RU/POR/ES/IT/AR.",
        button: "Angebot finden & konfigurieren",
        target: "/#offerings",
        icon: "Wirklichkeitsdichte-Agentur-Text-Copy.svg",
        title: "Wirklichkeitsdichte-Agentur-Text-Copy",
        alt: "Text, Ghostwriting, Copywriting von Wirklichkeitsdichte – Agentur für Text und B2B Content Strategien",
      
      },
      {
        id: 2,
        name: "Konzept",
        description:
          "Wir entwickeln mit euch eure Content-Strategie und Formate  für B2B oder B2C, umfassend oder spezifisch für Social Media, Magazine, Websites oder Blogs. Wir übernehmen auch die Redaktions- und Themenplanung und das Projektmanagement. Auch Konzept und Umsetzung von audiovisuellen Medien sind in unserem Repertoire.",
        button: "Konzeptservice anfragen",
        target:
          "mailto:info@wirklichkeitsdichte.org?subject=Konzeptservice anfragen",
        icon: "Wirklichkeitsdichte-Agentur-Konzept.svg",
        title: "Wirklichkeitsdichte-Agentur-Konzept",
        alt: "Konzept, Vereinfachung, Simplifikation von Wirklichkeitsdichte – Agentur für Text und B2B Content Strategien",
      },
      {
        id: 3,
        name: "Beratung",
        description:
          "Ihr habt Fragen rund um Text und die Menschen, die ihn schreiben? Wir beraten euch mit unserer jahrelangen Expertise und Erfahrung in diesen Themen: “Wie finde und binde ich Writer?” (HR), “Wie erkläre ich Dinge einfach?” (Simplification), “Wie gestalte ich die Wirklichkeit in meinem Unternehmen?”(Gamification).",
        button: "Kontaktieren",
        target: "mailto:info@wirklichkeitsdichte.org?subject=Beratung",
        icon: "Wirklichkeitsdichte-Agentur-Beratung.svg",
        title: "Wirklichkeitsdichte-Agentur-Beratung",
        alt: "Content Strategie, Vereinfachung, Personalberatung, Integration von Kreativen und Beratung von Wirklichkeitsdichte – Agentur für Text und B2B Content Strategien",
      },
    ],
    carroussel: [
      {
        id: 1,
        name: "Editor",
        price: 2500,
        description:
          "Wir recherchieren, schreiben und redigieren Texte für euer Magazin, für Print und Online.",
        offerings: [
          { id: 0, quantity: 6 },
          { id: 1, quantity: 4 },
          { id: 3, quantity: 10 },
        ],
      },
      {
        id: 2,
        name: "Translator",
        price: 2500,
        description:
          "Wir übersetzen eure Texte, Filme oder Audiomitschnitte und erstellen Untertitel für EN, RU, ESP, PT, IT.",
        offerings: [
          { id: 0, quantity: 5 },
          { id: 3, quantity: 11 },
        ],
      },
      {
        id: 3,
        name: "Social Media Editor",
        price: 2500,
        description:
          "Wir recherchieren und schreiben Feedposts und Stories für eure Social-Media-Kanäle.",
        offerings: [
          { id: 2, quantity: 12 },
          { id: 8, quantity: 36 },
          { id: 3, quantity: 8 },
        ],
      },
      {
        id: 4,
        name: "Copywriter",
        price: 2500,
        description: "Wir schreiben Long Copy und Short Copy für eure Kampagnen.",
        offerings: [
          { id: 0, quantity: 12 },
          { id: 2, quantity: 20 },
          { id: 8, quantity: 50 },
          { id: 3, quantity: 12 },
        ],
      },
      {
        id: 5,
        name: "Homepage Writer",
        price: 2500,
        description:
          "Wir schreiben eure Homepages, Landingpages und Produktseiten.",
        offerings: [
          { id: 3, quantity: 2 },
          { id: 5, quantity: 8 },
        ],
      },
      {
        id: 6,
        name: "Concepter",
        price: 2500,
        description:
          "Wir entwickeln mit euch Konzepte und formulieren diese verständlich für alle Stakeholder.",
        offerings: [
          { id: 3, quantity: 30 },
          { id: 4, quantity: 1 },
        ],
      },
      {
        id: 7,
        name: "Content Specialist",
        price: 2500,
        description:
          "Wir planen euren Content für euren Blog, Kanal, Magazin und andere Publikationen und kümmern uns um die Umsetzung.",
        offerings: [
          { id: 0, quantity: 4 },
          { id: 1, quantity: 4 },
          { id: 8, quantity: 40 },
          { id: 3, quantity: 10 },
          { id: 7, quantity: 1 },
          { id: 6, quantity: 1 },
          { id: 5, quantity: 6 },
          { id: 9, quantity: 1 },
        ],
      },
      {
        id: 8,
        name: "Writer Audio/Video",
        price: 2500,
        description:
          "Wir schreiben Skripte für euren Erklärfilm, Imagefilm oder für Film- bzw. Audiowerbung.",
        offerings: [
          { id: 3, quantity: 6 },
          { id: 7, quantity: 10 },
          { id: 6, quantity: 10 },
          { id: 4, quantity: 1 },
        ],
      },
      {
        id: 9,
        name: "Author",
        price: 2500,
        description:
          "Wir entwickeln mit euch ein Schreibkonzept und schreiben Inhalte für euren Blog, Whitepaper oder euer Buch.",
        offerings: [
          { id: 3, quantity: 70 },
          { id: 4, quantity: 1 },
        ],
      },
    ],
    clients: [
      {
        id: 0,
        name: "MYTY Group",
        jobDescription: "Full-Service Communications",
        ownerName: "David Rost",
        ownerTitle: "Chief Executive Officer",
        ownerBlurb:
          "“Das Wirklichkeitsdichte-Team unterstützt uns seit geraumer Zeit proaktiv, zuverlässig und mit großem Weitblick bei allen größeren und kleineren Herausforderungen rund um Redaktion, Pressearbeit und Content Marketing. Sie sind ein wichtiger Partner und eine tragende Säule unserer Öffentlichkeitsarbeit.”",
        accountDescription: "Pressemitteilungen, Fachbeiträge, Social Media, Koordination, interne Kommunikation, Beratung",
      },
      {
        id: 1,
        name: "INTEGR8",
        jobDescription: "Seamless Copywriting Experience",
        ownerName: "Floris Henning",
        ownerTitle: "Chief Strategy Officer",
        ownerBlurb:
          "“Als Partner von Wirklichkeitsdichte erhalten wir mit der Seamless Copywriting Experience monatlich vollumfassenden, flexiblen und ausfallsfreien Service in der Contentplanung und -produktion und decken damit ein Spektrum von SEO-Text bis hin zur B2B-Contentplattform ab.”",
        accountDescription:
          'Redaktion und Content für <a class="client-link" href="https://www.vattenfall.de/geschaeftskunden/mittelstand/magazin" target="_blank">Magazin für den Mittelstand</a>, SEO-Texte, Landingpage Texte, <a class="client-link" href="https://www.integr8.com/#insights" target="_blank">Blogbeiträge</a>, Community Management, Projektmanagement',
      },
      {
        id: 3,
        name: "sustainable natives e.G.",
        jobDescription: "Seamless Copywriting Experience",
        ownerName: "",
        ownerTitle: "",
        ownerBlurb:
          "Content-Strategie, Community-Management, Verfassen von Fachartikeln und die Entwicklung von Formaten betreiben wir seit Juli 2021 für die nachhaltige Unternehmensberatung sustainable natives mit Fokus auf LinkedIn.",
        accountDescription:
          "Community Management, Reportings, Erstellen von LinkedIn-Posts und Entwickeln von Formaten, Artikeln und Content inkl. Bildredaktion & Grafikdesign, Social-Media-Beratung",
      },
      {
        id: 4,
        name: "The Reach Group (TRG)",
        jobDescription: "Landingpages",
        ownerName: "Michael Rutkowski",
        ownerTitle: "Chief Executive Officer",
        ownerBlurb:
          "“Für die neue Homepage von TRG erstellte die Wirklichkeitsdichte GmbH neue Homepagetexte nach unseren Maßgaben. Schnell, suchmaschinenrelevant und auf den Punkt.”",
        accountDescription: "Homepage-Text, SEO-Text, Fachbeiträge, Blog",
      },
      {
        id: 5,
        name: "hr pepper consultants AG",
        jobDescription: "Homepage-Lektorat und SEO-Beratung",
        ownerName: "",
        ownerTitle: "",
        ownerBlurb:
          "Für die Unternehmensentwicklerinnen von hr pepper consultants lektorierten wir die Homepage nach den unternehmenseigenen Standards zur Gendersprache und optimierten mit Blick auf die Texte die Sichtbarkeit in Suchmaschinen.",
        accountDescription:
          "SEO-Text, Onpage-SEO-Beratung, Lektorat und inklusive Sprache.",
      },
      {
        id: 6,
        name: "HomeServe",
        jobDescription: "Homepage-Text, Sales Funnel, Presse",
        ownerName: "",
        ownerTitle: "",
        ownerBlurb:
          "Wir freuen uns riesig, dass wir HomeServe bei ihrem Start in den deutschen Markt mit Texten und Konzept für die Website sowie initialer PR unterstützen konnten. Besonders stolz sind wir auf das Framen des Tone of Voice des Unternehmens.",
        accountDescription:
          "PR-Beratung, Website Text, Website Sales Funnel, Content",
      },
      {
        id: 7,
        name: "Service Plan - House of Communication",
        jobDescription: "Social-Media-Text, B2B-Campaigning, Übersetzung",
        ownerName: "",
        ownerTitle: "",
        ownerBlurb:
          "Wir unterstützen Serviceplan, als eine in die Agentur eingebundene Anlaufstelle für Text, bei der Umsetzung von Inhalten für Kund:innen der Agentur, vor allem im Bereich Social Media, Konzeption und Newsletter.",
        accountDescription:
          "Content Konzeption, Social Media Copy, Konzeptentwicklung, Newsletter-Erstellung & Versand, Community Management",
      },
      {
        id: 8,
        name: "Deloitte",
        jobDescription: "On-Demand-Content",
        ownerName: "",
        ownerTitle: "",
        ownerBlurb:
          "Für Deloitte treten wir vor allem als Spezialist:innen in der Simplifikation von Inhalten in Erscheinung. Dabei geht es vor allem um Konzepte und Skripte für Erklär- und Animationsvideos. Darüber hinaus entwickelten wir Content für diverse Deloitte-Kund:innen in der Automobilbranche",
        accountDescription:
          "Erklärfilm Skript, Erklärfilm Konzept, Website-Texte, Newsletter Mail Chains, E-Mail-Kampagnen, Check-in- und Registration-Funnels.",
      },
    ],
    offerings: [
      {
        id: 0,
        category: "blogpost",
        name: "Blogartikel",
        price: 440,
        quantity: 1,
        chosen: false,
        info: {
          description:
            "…ein einfacher Artikel, frei oder mit bis zu zwei Quellen geschrieben. Ca. 800 und 1.200 Wörter lang. Suchmaschinenoptimiert in Überschriften und Paragraphen unterteilt und nach auf Wunsch SEO-Regeln geschrieben.",
          scope: "800-1.200 Wörter",
          links: "mind. 2",
          seo: true,
          pieces:
            "Konzept, Recherche, Projektmanagement, 1 Feedbackschleife, Lektorat",
        },
      },
      {
        id: 1,
        category: "deepdive",
        name: "Deep Dive Artikel",
        price: 750,
        quantity: 1,
        chosen: false,
        info: {
          description:
            "Ein komplexer Artikel mit journalistischem Anspruch bzw. ein Fachartikel mit mehreren Quellen. Ca. 1.200 bis 2.400 Wörter lang. Gemäß schriftstellerischer, journalistischer und fachliterarischer Qualitätskriterien verfasst und auf Wunsch nach SEO-Regeln optimiert.",
          scope: "1.200 - 2.400 Wörter",
          links: "mind.4",
          SEO: false,
          pieces:
            "Konzept, Recherche, Projektmanagement, 1 Feedbackschleife, Lektorat",
        },
      },
      {
        id: 2,
        category: "socialpost",
        name: "Social Post",
        price: 137.5,
        quantity: 1,
        chosen: false,
        info: {
          description:
            "Egal ob LinkedIn, Facebook oder Instagram, Dark Ad oder Organic Post: Wir konzipieren den Social Media Post und schreiben die Copy in einer Länge bis zu 1.200 Zeichen. Euer Art Department braucht nur noch die Assets zu erstellen. Auf Wunsch können Grafikdienstleistungen dazu gebucht werden.",
          scope: "90 - 800 Zeichen inkl. visueller Assets",
          links: "",
          SEO: false,
          pieces:
            "Konzept, Recherche, Projektmanagement, 1 Feedbackschleife, Lektorat",
        },
      },
      {
        id: 3,
        category: "hours",
        name: "zusätzliche Stunden",
        price: 94.6,
        quantity: 1,
        chosen: false,
        info: {
          description:
            "Arbeitsstunden im Paket. Damit erhaltet ihr erheblichen Rabatt auf Dienstleistungen, die wir über Stunden abrechnen. Dazu gehören Grafikdesign, Strategie, Konzept, Projektmanagement und mehr. Dabei sind die Rabattstufen ab 10, 20  und 100 Stunden. Im Angebotsgenerator sind alle Rabatte transparent ersichtlich.",
          scope: "",
          links: "",
          SEO: true,
          pieces: "",
        },
      },
      {
        id: 4,
        category: "consulting",
        name: "Beratung",
        price: 800,
        quantity: 1,
        chosen: false,
        info: {
          description:
            "Unser Beratungsangebot gliedert sich in drei Bereiche: Unter dem Stichwort HR beraten wir Euch darin, wie Ihr Texter:innen bindet und im Unternehmen haltet. Unter dem Stichwort Simplifikation erklären wir euch, wie ihr schwierige Sachverhalte einfach und effektiv kommuniziert. Unter dem Stichwort Gamification überlegen wir uns mit euch, wie wir Kultur und Arbeitsabläufe in eurem Unternehmen mit der Macht der Spiele optimieren können.",
          scope: "",
          links: "",
          SEO: true,
          pieces: "",
        },
      },
      {
        id: 5,
        category: "pagetext",
        name: "Website Page Text",
        price: 400,
        quantity: 1,
        chosen: false,
        info: {
          description:
            "Homepages, Landing Pages oder Kategorienseiten suchmaschinenrelevant betextet. Mit qualitativer Beratung zu SEO-Text. Preis gilt für eine Länge bis zu 800 Wörtern. Inklusive Einpflegen und Optimieren in Eurem Content Management System. ",
          scope: "800-1.200 Wörter",
          links: "mind. 2",
          seo: true,
          pieces:
            "Konzept, Recherche, Projektmanagement, 1 Feedbackschleife, Lektorat",
        },
      },

      {
        id: 6,
        category: "voiceover",
        name: "Voice Over Bewegtbild",
        price: 350,
        quantity: 1,
        chosen: false,
        info: {
          description:
            "… ein Sprechtext für euren Imagefilm, Werbeclip oder Animation, der Informationen und Aussagen entweder sachlich oder emotional für eure Zielgruppe formuliert. Passend auf euren Schnitt oder in Vorbereitung darauf.",
          scope: "800-1.200 Wörter",
          links: "mind. 2",
          seo: true,
          pieces:
            "Konzept, Recherche, Projektmanagement, 1 Feedbackschleife, Lektorat",
        },
      },

      {
        id: 7,
        category: "explainer",
        name: "Skript Erklärfilm",
        price: 800,
        quantity: 1,
        chosen: false,
        info: {
          description:
            "Konzeption für einen Erklärfilm der Produkte, Dienstleistungen, Sachverhalte zielgruppengerecht vereinfacht. Bis 120 Sekunden Länge. Enthält nach Szenen aufgeteilten Sprechtext für das Voice-Over sowie eine Bildbeschreibung. Auf Wunsch auch mit Animationsanweisungen. Nach den pädagogischen Grundregeln des Erklärfilms optimiert.",
          scope: "800-1.200 Wörter",
          links: "mind. 2",
          seo: true,
          pieces: [
            "Weitere Minute Film",
            "Storyboard mit Skizzen",
            "Storyboard mit Stilentwicklung / Stil-Übernahme",
            "2D-Animation",
          ],
        },
      },

      {
        id: 8,
        category: "storypost",
        name: "Social Story Slide",
        price: 25,
        quantity: 1,
        chosen: false,
        info: {
          description:
            "Die passende Story für eure Social Media Channels passend zu gebuchten Social Media Feed Posts oder einzeln. Konzipiert mit Blick auf eure Zielgruppen und KPI schreiben wir die Copy so, dass euer Art Department nur noch loslegen muss. Auf Wunsch können Grafikdienstleistungen dazu gebucht werden.",
          scope: "800-1.200 Wörter",
          links: "mind. 2",
          seo: true,
          pieces:
            "Konzept, Recherche, Projektmanagement, 1 Feedbackschleife, Lektorat",
        },
      },
      {
        id: 9,
        category: "contentstrategyws",
        name: "Content Strategie Workshop",
        price: 1250,
        quantity: 1,
        chosen: false,
        info: {
          description:
            "Ein Content Strategie Workshop ist die beste Form eines Kickoff für den Beginn einer neuen Zusammenarbeit, sofern ihr euer Projekt jetzt erst beginnt. So können wir gemeinsam alle Ideen und Vorstellungen besprechen, strukturieren und die Grundlage für einen Redaktionsplan und Contentproduktion legen.",
          scope: "800-1.200 Wörter",
          links: "mind. 2",
          seo: true,
          pieces:
            "Konzept, Recherche, Projektmanagement, 1 Feedbackschleife, Lektorat",
        },
      },
    ],
    request: [],
    shownInfo: {},
    showItemsMobile: false,
    showEditorItems: true,
    showInfoMobile: false,
    showDetailModal: false,
    cookiesAccepted: false,
    landingVisited: false,
    visibleChapter: "you-tab",
    visibleChapterLegal: "imprint-tab",
    visibleChapterMain: "services-tab",
    globalOverflow: true,
    overlayOpen: false,
    generatorActive: false,
    scrollLocked: false,
    chosenCarrousselColumn: "",
  },
  getters: {
    // landing page animation
    CAMERA_POSITION: (state) => {
      return state.camera ? state.camera.position : null;
    },

    getChosenOfferings(state) {
      const chosenOfferings = state.offerings.filter(
        (offering) => offering.chosen === true
      );
      return chosenOfferings;
    },
    getOfferingById: (state) => (id) => {
      return state.offerings.find((offering) => offering.id === id);
    },
    getOfferingByCategory: (state) => (category) => {
      return state.offerings.find((offering) => offering.category === category);
    },
    getColumnOfferings: (state, getters) => (columnData) => {
      let columnOfferings = [];
      // console.log(columnData + " length: " + columnData.length);

      // return apropriate offerings for the column
      for (let i = 0; i < columnData.length; i++) {
        let offering = getters.getOfferingById(columnData[i].id);
        // console.log("columndata quantity: " + columnData[i].quantity);
        offering.quantity = columnData[i].quantity;
        // console.log("offering quantity: " + offering.quantity);
        columnOfferings.push(offering);
      }

      return columnOfferings;
    },
    getBasePriceColumn: (state, getters) => (columnData) => {
      let columnOfferings = [];
      // console.log(columnData + " length: " + columnData.length);

      // return apropriate offerings for the column
      for (let i = 0; i < columnData.length; i++) {
        let offering = getters.getOfferingById(columnData[i].id);
        // console.log("columndata quantity: " + columnData[i].quantity);
        offering.quantity = columnData[i].quantity;
        // console.log("offering quantity: " + offering.quantity);
        columnOfferings.push(offering);
      }

      return columnOfferings.reduce((total, offering) => {
        return total + offering.price * offering.quantity;
      }, 0);
    },
    getShownInfo(state) {
      return state.shownInfo;
    },
    getFormatInfo(state) {
      return state.formats;
    },
    cookiesAccepted(state) {
      return state.cookiesAccepted;
    },
    getVisibleChapter(state) {
      return state.visibleChapter;
    },
    getVisibleChapterLegal(state) {
      return state.visibleChapterLegal;
    },
    getVisibleChapterMain(state) {
      return state.visibleChapterMain;
    },
    getScrollLocked(state) {
      return state.scrollLocked;
    },
    getchosenCarrousselColumn(state) {
      return state.chosenCarrousselColumn;
    },
  },
  mutations: {
    // landing page animation stuff for three.js

    SET_VIEWPORT_SIZE(state, { width, height }) {
      state.width = width;
      state.height = height;
    },

    INITIALIZE_RENDERER(state, el) {
      state.renderer = new WebGLRenderer({ antialias: false });
      state.renderer.setPixelRatio(window.devicePixelRatio);
      state.renderer.setSize(state.width, state.height);
      state.renderer.gammaInput = true;
      state.renderer.gammaOutput = true;

      el.appendChild(state.renderer.domElement);
    },

    INITIALIZE_POSTPROCESSING(state) {
      const composer = new EffectComposer(state.renderer);
      const renderPass = new RenderPass(state.scene, state.camera);
      renderPass.clear = false;
      composer.addPass(renderPass);

      state.postprocessing.composer = composer;
    },

    INITIALIZE_CAMERA(state) {
      state.camera = new PerspectiveCamera(
        // 1. Field of View (degrees)
        110,
        // 2. Aspect ratio
        state.width / state.height,
        // 3. Near clipping plane
        1,
        // 4. Far clipping plane
        1000
      );
      state.camera.position.z = 135;
    },

    INITIALIZE_CONTROLS(state) {
      state.controls = new TrackballControls(
        state.camera,
        state.renderer.domElement
      );
      state.controls.rotateSpeed = 1.0;
      state.controls.zoomSpeed = 1.2;
      state.controls.panSpeed = 0.8;
      state.controls.noZoom = false;
      state.controls.noPan = false;
      state.controls.staticMoving = true;
      state.controls.dynamicDampingFactor = 0.3;
    },

    INITIALIZE_SCENE(state) {
      state.scene = new Scene();
      state.scene.background = new Color(0xffffff);
      state.scene.fog = new FogExp2(0xffffff, 0.003);
      let geometry = new CylinderBufferGeometry(0, 10, 30, 4, 1);
      let material = new MeshPhongMaterial({
        color: 0xffffff,
        flatShading: false,
      });
      for (var i = 0; i < 500; i++) {
        var mesh = new Mesh(geometry, material);
        mesh.position.x = (Math.random() - 0.5) * 1000;
        mesh.position.y = (Math.random() - 0.5) * 1000;
        mesh.position.z = (Math.random() - 0.5) * 1000;
        mesh.updateMatrix();
        mesh.matrixAutoUpdate = false;
        state.pyramids.push(mesh);
      }

      var fontloader = new FontLoader();
      let font = fontloader.parse(acumin);

      function letterMesh(symbol, font, size, segments) {
        const geometry = new TextGeometry(symbol, {
          font: font,
          size: size,
          height: 0,
          curveSegments: segments,
        });
        let material = new MeshPhongMaterial({
          color: 0xff0000,
          flatShading: false,
        });

        let mesh = new Mesh(geometry, material);
        mesh.matrixAutoUpdate = true;
        return mesh;
      }

      function poseMesh(mesh, x, y, z) {
        mesh.geometry.center();
        mesh.position.x = x;
        mesh.position.y = y;
        mesh.position.z = z;
      }

      function rotateMesh(mesh, xDeg, yDeg, zDeg) {
        /* let rotQuaternion = new Quaternion();
        const xAxis = new Vector3(1, 0, 0);
        const yAxis = new Vector3(0, 1, 0);
        const zAxis = new Vector3(0, 0, 1);
        rotQuaternion.setFromAxisAngle(xAxis, xDeg);
        rotQuaternion.setFromAxisAngle(yAxis, yDeg);
        rotQuaternion.setFromAxisAngle(zAxis, zDeg); */
        mesh.geometry.center();
        mesh.rotation.x = xDeg;
        mesh.rotation.y = yDeg;
        mesh.rotation.z = zDeg;
      }

      /* function rotateQuaternion(mesh, xDeg, yDeg, zDeg) {
        let meshCopy = structuredClone(mesh);
        rotateMesh(meshCopy, xDeg, yDeg, zDeg);
        return meshCopy.quaternion;
      } */

      let M = letterMesh("M", font, 80, 16);
      poseMesh(M, -100, 70, 0);
      rotateMesh(M, -0.2, -0.3, 0.6);

      let o = letterMesh("o", font, 80, 16);
      poseMesh(o, -30, 30, 60);
      rotateMesh(o, 0.2, 0.1, 0);

      let I = letterMesh("i", font, 80, 16);
      poseMesh(I, 0, 0, -10);
      rotateMesh(I, 0.4, -0.5, 0.5);

      let n = letterMesh("n", font, 80, 16);
      poseMesh(n, 50, 0, -70);
      rotateMesh(n, -0.3, 0.5, 0.1);

      let bang = letterMesh("!", font, 80, 16);
      poseMesh(bang, 130, -10, -180);
      rotateMesh(bang, -0.3, 0.4, -0.4);

      state.letters.push(M);
      state.letters.push(o);
      state.letters.push(I);
      state.letters.push(n);
      state.letters.push(bang);
      state.scene.add(...state.letters);
      state.scene.add(...state.pyramids);

      // animation testing

      // Create an animation clip that moves the cube back and forth along the x-axis
      const positionKFM = new VectorKeyframeTrack(
        ".position",
        [0, 1, 2],
        [
          M.position.x,
          M.position.y,
          M.position.z,
          M.position.x,
          M.position.y + 10,
          M.position.z,
          M.position.x,
          M.position.y,
          M.position.z,
        ]
      );

      const positionKFo = new VectorKeyframeTrack(
        ".position",
        [0, 1, 2],
        [
          o.position.x,
          o.position.y,
          o.position.z,
          o.position.x + 5,
          o.position.y - 20,
          o.position.z,
          o.position.x,
          o.position.y,
          o.position.z,
        ]
      );

      const positionKFi = new VectorKeyframeTrack(
        ".position",
        [0, 1, 2],
        [
          I.position.x,
          I.position.y,
          I.position.z,
          I.position.x - 5,
          I.position.y - 15,
          I.position.z + 10,
          I.position.x,
          I.position.y,
          I.position.z,
        ]
      );

      const positionKFn = new VectorKeyframeTrack(
        ".position",
        [0, 1, 2],
        [
          n.position.x,
          n.position.y,
          n.position.z,
          n.position.x - 12,
          n.position.y - 12,
          n.position.z - 12,
          n.position.x,
          n.position.y,
          n.position.z,
        ]
      );

      const positionKFbang = new VectorKeyframeTrack(
        ".position",
        [0, 1, 2],
        [
          bang.position.x,
          bang.position.y,
          bang.position.z,
          bang.position.x - 14,
          bang.position.y,
          bang.position.z,
          bang.position.x,
          bang.position.y,
          bang.position.z,
        ]
      );

      // set up rotation about y axis
      /* const qInitial = M.quaternion;
      const qFinal = rotateQuaternion(M, 0.5, 0, 0);
      const rotationKFo = new QuaternionKeyframeTrack(
        ".quaternion",
        [0, 1, 2],
        [
          qInitial.x,
          qInitial.y,
          qInitial.z,
          qInitial.w,
          qFinal.x,
          qFinal.y,
          qFinal.z,
          qFinal.w,
          qInitial.x,
          qInitial.y,
          qInitial.z,
          qInitial.w,
        ]
      ); */

      const clipM = new AnimationClip("UpAndDown", -1, [positionKFM]);
      const clipO = new AnimationClip("UpAndDown", -1, [positionKFo]);
      const clipI = new AnimationClip("UpAndDown", -1, [positionKFi]);
      const clipN = new AnimationClip("UpAndDown", -1, [positionKFn]);
      const clipBang = new AnimationClip("UpAndDown", -1, [positionKFbang]);

      // Create an animation mixer and add the clip to it
      state.mixer = new AnimationMixer(state.letters[0]);
      const actionM = state.mixer.clipAction(clipM);
      actionM.play();

      state.mixerO = new AnimationMixer(state.letters[1]);
      const actionO = state.mixerO.clipAction(clipO);
      actionO.play();

      state.mixerI = new AnimationMixer(state.letters[2]);
      const actionI = state.mixerI.clipAction(clipI);
      actionI.play();

      state.mixerN = new AnimationMixer(state.letters[3]);
      const actionN = state.mixerN.clipAction(clipN);
      actionN.play();

      state.mixerBang = new AnimationMixer(state.letters[4]);
      const actionBang = state.mixerBang.clipAction(clipBang);
      actionBang.play();

      // lights
      var lightA = new DirectionalLight(0xffffff);
      lightA.position.set(10, 10, 10);
      state.scene.add(lightA);
      var lightB = new DirectionalLight(0xffffff);
      lightB.position.set(-5, -5, -5);
      state.scene.add(lightB);
      var lightC = new AmbientLight(0x222222);
      state.scene.add(lightC);

      var lightD = new DirectionalLight(0xffffff);
      lightD.position.set(-5, -5, 5);
      state.scene.add(lightD);

      // Axis Line 1
      var materialB = new LineBasicMaterial({ color: 0x0000ff });
      var geometryB = new Geometry();
      geometryB.vertices.push(new Vector3(0, 0, 0));
      geometryB.vertices.push(new Vector3(0, 1000, 0));
      var lineA = new Line(geometryB, materialB);
      state.axisLines.push(lineA);
      // Axis Line 2
      var materialC = new LineBasicMaterial({ color: 0x00ff00 });
      var geometryC = new Geometry();
      geometryC.vertices.push(new Vector3(0, 0, 0));
      geometryC.vertices.push(new Vector3(1000, 0, 0));
      var lineB = new Line(geometryC, materialC);
      state.axisLines.push(lineB);
      // Axis 3
      var materialD = new LineBasicMaterial({ color: 0xff0000 });
      var geometryD = new Geometry();
      geometryD.vertices.push(new Vector3(0, 0, 0));
      geometryD.vertices.push(new Vector3(0, 0, 1000));
      var lineC = new Line(geometryD, materialD);
      state.axisLines.push(lineC);
      state.scene.add(...state.axisLines);
    },

    RESIZE(state, { width, height }) {
      state.width = width;
      state.height = height;
      state.camera.aspect = width / height;
      state.camera.updateProjectionMatrix();
      state.renderer.setSize(width, height);
      // state.postprocessing.composer.setSize(width, height);
      state.controls.handleResize();
      state.postprocessing.composer.renderer.render(state.scene, state.camera);
      // state.renderer.render(state.scene, state.camera);
    },

    SET_CAMERA_POSITION(state, { x, y, z }) {
      if (state.camera) {
        state.camera.position.set(x, y, z);
      }
    },
    RESET_CAMERA_ROTATION(state) {
      if (state.camera) {
        state.camera.rotation.set(0, 0, 0);
        state.camera.quaternion.set(0, 0, 0, 1);
        state.camera.up.set(0, 1, 0);
        state.controls.target.set(0, 0, 0);
      }
    },
    HIDE_AXIS_LINES(state) {
      state.scene.remove(...state.axisLines);
      state.renderer.render(state.scene, state.camera);
    },
    SHOW_AXIS_LINES(state) {
      state.scene.add(...state.axisLines);
      state.renderer.render(state.scene, state.camera);
    },
    HIDE_PYRAMIDS(state) {
      state.scene.remove(...state.pyramids);
      state.renderer.render(state.scene, state.camera);
    },
    SHOW_PYRAMIDS(state) {
      state.scene.add(...state.pyramids);
      state.renderer.render(state.scene, state.camera);
    },

    // offering editor mutations

    getObjIndex(state, id) {
      return state.offerings.findIndex((offering) => offering.id === id);
    },
    addToList(state, id) {
      //Find index of specific object using findIndex method.
      let objIndex = state.offerings.findIndex(
        (offering) => offering.id === id
      );
      //Update object's name property.
      state.offerings[objIndex].chosen = true;
      state.offerings[objIndex].quantity = 1;
    },
    deleteFromList(state, id) {
      //Find index of specific object using findIndex method.
      let objIndex = state.offerings.findIndex(
        (offering) => offering.id === id
      );

      //Update object's name property.
      state.offerings[objIndex].chosen = false;
    },

    setInfo(state, id) {
      let objIndex = state.offerings.findIndex(
        (offering) => offering.id === id
      );

      state.shownInfo = Object.assign({}, state.offerings[objIndex]);
    },

    addQuantity(state, id) {
      let objIndex = state.offerings.findIndex(
        (offering) => offering.id === id
      );
      state.offerings[objIndex].quantity++;

      // update shownInfo for DetailModal!
      state.shownInfo = Object.assign({}, state.offerings[objIndex]);
    },

    substractQuantity(state, id) {
      let objIndex = state.offerings.findIndex(
        (offering) => offering.id === id
      );
      if (state.offerings[objIndex].quantity > 1) {
        state.offerings[objIndex].quantity--;
      }
      // update shownInfo for DetailModal!
      state.shownInfo = Object.assign({}, state.offerings[objIndex]);
    },
    resetQuantity(state, id) {
      let objIndex = state.offerings.findIndex(
        (offering) => offering.id === id
      );
      if (state.offerings[objIndex].quantity > 1) {
        state.offerings[objIndex].quantity = 1;
      }
      // update shownInfo for DetailModal!
      state.shownInfo = Object.assign({}, state.offerings[objIndex]);
    },
    changePrice(state, payload) {
      let objIndex = state.offerings.findIndex(
        (offering) => offering.id === payload.id
      );

      console.log(payload.amount);
      state.offerings[objIndex].price = payload.amount; // should be filtered for floats and absurd amounts
    },

    enterQuantity(state, payload) {
      let objIndex = state.offerings.findIndex(
        (offering) => offering.id === payload.id
      );

      state.offerings[objIndex].quantity = payload.amount; // should be filtered for floats and absurd amounts
    },

    resetChosenOfferingsList(state) {
      state.offerings.map((offering) => {
        offering.chosen = false;
        offering.quantity = 0;
      });

      // state.offerings.forEach((offering) => console.log(" chosen: " + offering.chosen + " quantity: " + offering.quantity))
    },
    toggleMobileList(state) {
      state.showItemsMobile = !state.showItemsMobile;
    },
    toggleEditorItems(state) {
      state.showEditorItems = !state.showEditorItems;
    },
    toggleMobileInfo(state) {
      state.showInfoMobile = !state.showInfoMobile;
    },
    toggleMobileItemOrInfo(state) {
      state.showItemsMobile = false;
      state.showInfoMobile = false;
    },
    toggleDetailModal(state) {
      state.showDetailModal = !state.showDetailModal;
    },
    acceptCookies(state) {
      state.cookiesAccepted = true;
      localStorage.accept_cookies = true;
    },

    landingAnimationVisited(state) {
      state.landingVisited = true;
      localStorage.landing_animation_visited = true;
      localStorage.landing_animation_last_visit = new Date();
    },
    setVisibleChapter(state, hash) {
      state.visibleChapter = hash;
    },
    setVisibleChapterLegal(state, hash) {
      state.visibleChapterLegal = hash;
    },
    setVisibleChapterMain(state, hash) {
      state.visibleChapterMain = hash;
    },
    toggelOverflow(state) {
      state.globalOverflow = !state.globalOverflow;
    },
    toggleOverlay(state) {
      state.overlayOpen = !state.overlayOpen;
    },
    toggleGenerator(state) {
      state.generatorActive = !state.generatorActive;
    },
    toggleScrollLock(state) {
      state.scrollLocked = !state.scrollLocked;
    },
    setScrollLock(state, bool) {
      state.scrollLocked = bool;
    },
    setChosenCarrousselColumn(state, name) {
      state.chosenCarrousselColumn = name;
    },
    resetGeneratorDefaults(state) {
      state.shownInfo = {};
      state.showItemsMobile = false;
      state.showEditorItems = true;
      state.showInfoMobile = false;
      state.showDetailModal = false;
      state.generatorActive = false;
      state.scrollLocked = false;
    },
  },
  actions: {
    INIT({ state, commit }, { width, height, el }) {
      return new Promise((resolve) => {
        commit("SET_VIEWPORT_SIZE", { width, height });
        commit("INITIALIZE_RENDERER", el);
        commit("INITIALIZE_CAMERA");
        commit("INITIALIZE_CONTROLS");
        commit("INITIALIZE_SCENE");
        commit("INITIALIZE_POSTPROCESSING");
        // Initial scene rendering
        // state.renderer.render(state.scene, state.camera);
        state.postprocessing.composer.render(0.1);
        // Add an event listener that will re-render
        // the scene when the controls are changed

        state.controls.addEventListener("change", () => {
          state.postprocessing.composer.render(0.1);
          // state.renderer.render(state.scene, state.camera);
        });
        resolve();
      });
    },

    ANIMATE({ state, dispatch }) {
      window.requestAnimationFrame(() => {
        dispatch("ANIMATE");

        state.mixer.update(0.002);
        state.mixerO.update(0.001);
        state.mixerI.update(0.003);
        state.mixerN.update(0.002);
        state.mixerBang.update(0.004);
        //state.renderer.render(state.scene, state.camera);
        state.postprocessing.composer.renderer.render(
          state.scene,
          state.camera
        );
        //console.log(state.postprocessing.composer);

        state.controls.update();
      });
    },
  },
  modules: {},
});
