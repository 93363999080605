<template>
  <div>
    
    <MenuHeaderTabed />
      <Landing />
    <div
      id="main-container"
      class="main-container"
    >
      <ServicesContent id="services" />
      <div class="separator" />
      <CarrousselContent
        id="offerings"
        @scrollblock="scrollBlock"
      />
      <TeamContainer />
      <div class="separator" />
      <ClientContent />
      <div class="separator" />
      <AboutContent />
      <div class="separator" />
      <ContactContent id="contact" />
     
      <LegalBanner />
     
      <CookieBanner />
    </div>
  </div>
</template>

<script>
import MenuHeaderTabed from "../components/content-page/MenuHeaderTabed.vue";
import ServicesContent from "../components/services/ServicesContent.vue";
import CarrousselContent from "../components/carroussel/CarrousselContent.vue";
import TeamContainer from "../components/team/TeamContainer.vue";
import ClientContent from "../components/clients/ClientContent.vue";
import AboutContent from "../components/contact/AboutContent.vue"
import ContactContent from "../components/contact/ContactContent.vue"
import LegalBanner from "../components/about/LegalBanner.vue";
import CookieBanner from "../components/CookieBanner.vue";
import Landing from "../components/landing/Landing.vue"

export default {
  name: "Scroller",
  metaInfo() {
    return {
      meta: [
        {
          name: 'Wirklichkeitsdichte | B2B-Content-Strategie & Text',
          content: 'Inhalte entscheiden - Wirklichkeitsdichte aus Berlin entwickelt eure B2B-Content-Strategie und schreibt Quality Content für alle Kanäle.'
        },
        {
          name: 'Wirklichkeitsdichte | Content Services für B2B & B2C',
        },
        {
          name: 'Wirklichkeitsdichte | Angebotsgenerator',
          content: '100% Transparenz. Angebote für Quality Content mit unserem Angebotsgenerator Paket wählen und konfigurieren, sofort Preise und Rabatte sehen.'
        },
        {
          name: 'Wirklichkeitsdichte | Team für Content und mehr',
          content: '100 % burnoutfreie Texte von einem thematisch breit aufgestellten Team erfahrener Kreativer, Texter:innen und Kommunikationsprofis.'
        },
        {
          name: 'Wirklichkeitsdichte | Clients & Referenzen',
          content: 'Vom Pressetext bis zur Full-Service-Communication: Unsere Kund:innen sind große Player aus Agenturbranche, Mittelstand, Startups und Beratungen.'
        },
        {
          name: 'Wirklichkeitsdichte | Über unsere burnoutfreie Arbeit',
          content: 'Unsere Prämisse "Geistige Gesundheit first" und ein echtes Verständnis für die Arbeits weise von Texter:innen macht uns einzigartig. Mehr über uns.'
        },
        {
          name: 'Wirklichkeitsdichte | Kontakt für Quality Content',
          content: 'Ihr braucht eine Content-Strategie oder "nur" tief recherchierte und qualitative Websitetexte, Blogartikel, Fachbeiträge? Kontaktiert uns. \r https://wirklichkeitsdichte.org/about \r Guter Content braucht mehr Wirklichkeitsdichte \r Warum Wirklichkeitsdichte? Ihr kriegt nur dann guten Content, wenn eure Kreativen glücklich sind. Unsere Agentur hat Lösung dafür. Mehr lesen.'
        },

      ]
    }
  },
  components: {
    MenuHeaderTabed,
    ServicesContent,
    CarrousselContent,
    TeamContainer,
    ClientContent,
    AboutContent,
    ContactContent,
    LegalBanner,
    CookieBanner,
    Landing,
  },
  data() {
    return {
      blocked: true,
    };
  },
  computed: {
    generatorActive() {
      return this.$store.state.generatorActive;
    },
    scrollLocked() {
      return this.$store.state.scrollLocked;
    },

  },
  mounted() {
    // window.addEventListener("resize", this.handleResize);
    // this.handleResize();
    const curtain = document.querySelector(".curtain");
    const cookiebanner = document.querySelector(".cookie-banner")
    if (this.curtainVisited()) {
        if (curtain) {
          curtain.remove();
          document.body.style.overflow = "";
        }
    }

    if (this.$store.getters.cookiesAccepted) {
      if (cookiebanner) {
          cookiebanner.remove();
        }
    }
  },

  destroyed() {
    
  },

  methods: {
    curtainVisited() {
      const then = new Date(localStorage.landing_animation_last_visit);
      const now = new Date();
      const msBetweenDates = Math.abs(then.getTime() - now.getTime());
      const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

      if (hoursBetweenDates < 12) return true
      else return false
    },
    handleResize() {
      if (!this.generatorActive) {
        let id = this.$store.getters.getVisibleChapterMain.slice(0, -4); // get active tab and get rid of the '-tab' suffix
        let center = document.getElementById(id);
        center.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    },
    scrollBlock() {
      const root = document.body;
      // const root = document.getElementById("offerings");

      if (this.scrollLocked) {
        root.classList.remove("scrollblock");
      } else {
        root.classList.add("scrollblock");
      }
    },
  },
};
</script>
