<template>
  <div
    id="contact"
    class="contact-container"
  >
    <Contact />
  </div>
</template>
<script>
import Contact from "./Contact.vue";

export default {
  name: "ClientContent",
  components: {
    Contact,
  },

  data() {
    return {
      button: true,
    };
  },
  computed: {
    generatorActive() {
      return this.$store.state.generatorActive;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (!this.generatorActive) {
        const viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;
        let bounding = document
          .getElementById("contact")
          .getBoundingClientRect();
        if (viewportHeight - bounding.top > 0 && bounding.bottom > 0) {
          this.$store.commit("setVisibleChapterMain", "contact-tab");
        }
      }
    },
  },
};
</script>
