<template>
  <div class="carroussel-footer">
    <div
      class="carroussel-arrow"
      @click="scrollLeft()"
    >
      <img
        title="scroll left"
        src="~@/assets/arrow-left.svg"
      >
    </div>
    <div class="carroussel-dots">
      <img
        v-for="dot in dots"
        :id="dot"
        :key="dot"
        class="carroussel-dot-blur"
        :class="{ 'carroussel-dot-sharp': dot == active }"
        title="dot"
        src="~@/assets/dot.svg"
        @click="slideTo(dot)"
      >
    </div>
    <div
      class="carroussel-arrow"
      @click="scrollRight()"
    >
      <img
        title="scroll right"
        src="~@/assets/arrow-right.svg"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dots: Number,
    active: Number,
  },
  data() {
    return {
      dot: this.dot,
    };
  },
  computed: {},
  methods: {
    slideTo(id) {
      let column = document.getElementById("carroussel-column-" + id);
      column.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest"
      });
    },
    scrollLeft() {
      if(this.active === 1) {
        this.slideTo(this.dots)
      } else this.slideTo(this.active - 1)
    },
    scrollRight() {
       if(this.active === this.dots) {
        this.slideTo(1)
      } else this.slideTo(this.active + 1)
    },
  },
};
</script>
