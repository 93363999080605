<template>
  <div v-if="opened">
    <div class="editor-items-mobile">
      <div>
        <h3>Konfigurieren</h3>

        <p>
          Wähle weitere Produkte aus und füge sie so deinem individuellen
          Angebot hinzu.
        </p>

        <p>Text und Konzept</p>
      </div>

      <div class="offered-item-list">
        <ul>
          <OfferedItemMobile
            v-for="(offerings, index) in offerings"
            :key="index"
            :offerings="offerings"
          />
        </ul>
      </div>
    </div>
    <div
      class="editor-items-mobile-backdrop"
      @click="toggleMobileItemList()"
    />
    <div
      class="close-editor-items-mobile"
      @click="toggleMobileItemList()"
    >
      <img src="~@/assets/closebutton_1pt.svg">
    </div>
  </div>
</template>
<script>
import OfferedItemMobile from "../generator/OfferedItemMobile.vue";
export default {
  components: {
    OfferedItemMobile,
  },
  data() {
    return {
      //offerings: this.$store.getters.getChosenOfferings,
    };
  },
  computed: {
    opened() {
      console.log(this.$store.state.showItemsMobile);
      return this.$store.state.showItemsMobile;
    },
    offerings() {
      return this.$store.state.offerings;
    },
  },
  methods: {
    toggleMobileItemList() {
      this.$store.commit("toggleMobileList");
    },
  },
};
</script>
