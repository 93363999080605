<template>
  <li>
    <div class="offering-item">
      <span
        class="offering-item-name"
        @click="$emit('setInfo')"
      >{{ offerings.name }}</span>
      <div class="offering-price-container">
        <span class="offering-multiplier">
          {{ offerings.quantity + " x" }}</span>
        <span class="offering-counter">{{ offerings.price }} €</span>
        <img
          title="Angebot Details"
          class="detail-item"
          src="~@/assets/plus.svg"
          @click="$emit('setInfo')"
        >
      </div>
    </div>
    <div class="count-delete">
      <img
        title="Angebot entfernen"
        class="delete-item end"
        src="~@/assets/trash.svg"
        @click="$emit('delete')"
      >
    </div>
  </li>
</template>
<script>
export default {
  name: "ChosenOffering",
  props: {
    offerings: Object,
  },
  data() {
    return {};
  },
};
</script>
