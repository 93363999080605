<template>
  <div
    id="team"
    class="team-container"
  >
    <h1 class="main-section-header">
      Team
    </h1>
    <Profile
      v-for="(profiles, index) in profiles"
      :key="index"
      :profiles="profiles"
    />
  </div>
</template>
<script>
import Profile from "./Profile.vue";

export default {
  name: "Services",
  components: {
    Profile,
  },
  data() {
    return {};
  },
  computed: {
    profiles() {
      // shuffle array and return
      let profiles_shuffle = this.$store.state.profiles;

      for (let i = profiles_shuffle.length - 1; i > 0; i--) {
        // Generate random number
        let j = Math.floor(Math.random() * (i + 1));

        let temp = profiles_shuffle[i];
        profiles_shuffle[i] = profiles_shuffle[j];
        profiles_shuffle[j] = temp;
      }

      return profiles_shuffle;
      
      // return this.$store.state.profiles;
    },
    generatorActive() {
      return this.$store.state.generatorActive;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (!this.generatorActive) {
        const viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;
        let bounding = document.getElementById("team").getBoundingClientRect();
        if (viewportHeight - bounding.top > 0 && bounding.bottom > 0) {
          this.$store.commit("setVisibleChapterMain", "team-tab");
        }
      }
    },
  },
};
</script>
