<template>
  <div class="client">
    <div class="client-header desktop">
      <h1>{{ clients.name }}</h1>
      <h3>{{ clients.jobDescription }}</h3>
    </div>

    <div class="client-description">
      <div class="client-header tablet">
        <h1>{{ clients.name }}</h1>
        <h3>{{ clients.jobDescription }}</h3>
      </div>
      <p class="client-owner-blurb"  v-html="clients.ownerBlurb">
      </p>
      <h3 class="owner-name">
        {{ clients.ownerName }}
      </h3>
      <p class="owner-title">
        {{ clients.ownerTitle }}
      </p>

      <img
        class="client-arrow"
        src="~@/assets/rightarrow_1pt.svg"
        @click="openFullClient()"
      >
    </div>

    <div class="client-account">
      <span>Services</span>
      <br>
      <p v-html="clients.accountDescription">
      </p>
    </div>

    <ClientModalMobile
      :class="{ 'client-modal-active': active }"
      :clients="clients"
      @closeClientModal="openFullClient"
    />
  </div>
</template>

<script>
import ClientModalMobile from "./ClientModalMobile.vue";
export default {
  name: "ClientColumn",
  components: {
    ClientModalMobile,
  },
  props: {
    clients: Object,
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    openFullClient() {
      this.active = !this.active;
      if (this.active) {
        // When the modal is shown, we want a fixed body
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "initial";
      }
    },
  },
};
</script>
