<template>
  <div
    id="about"
    class="contact-container"
  >
    <About />
  </div>
</template>
<script>
import About from "./About.vue";

export default {
  name: "ClientContent",
  components: {
    About,
  },

  data() {
    return {
      button: true,
    };
  },
  computed: {
    generatorActive() {
      return this.$store.state.generatorActive;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (!this.generatorActive) {
        const viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;
        let bounding = document
          .getElementById("about")
          .getBoundingClientRect();
        if (viewportHeight - bounding.top > 0 && bounding.bottom > 0) {
          this.$store.commit("setVisibleChapterMain", "about-tab");
        }
      }
    },
  },
};
</script>
