<template>
  <div class="menu-header-tabed-narrow">
    <nav class="nav-tabs">
      <a class="invertselected">{{ getActive }}</a>
    </nav>
  </div>
</template>
<script>
export default {
  components: {},
  computed: {
    getActive() {
      let tab = "Service";

      switch (this.$store.getters.getVisibleChapterMain) {
        case "services-tab":
          tab = "Services";
          break;
        case "offerings-tab":
          tab = "Angebotsgenerator";
          break;
        case "team-tab":
          tab = "Team";
          break;

        case "clients-tab":
          tab = "Clients";
          break;

        case "about-tab":
          tab = "About";
          break;
        case "contact-tab":
          tab = "Kontakt";
          break;

        default:
          tab = "Services";
      }
      return tab;
    },
  },
};
</script>
