<template>
  <div v-if="opened">
    <div class="close-info-tablet" @click="toggleTabletInfoList()">
      <img src="~@/assets/cross.svg" />
    </div>
    <div class="info-mobile-backdrop" @click="toggleMobileInfoList" />
    <div class="info-mobile mobile column">
      <div class="info-descriptions">
        <h3>Eure Seamless Writing Experience</h3>
        <h3>Info</h3>
        <p>
          Wählt euch eure Formate wie Blogartikel, Social Media Post oder passt
          eines unserer Pakete eurem Bedarf an. Entscheidet wieviele Einheiten
          eines Formats ihr benötigt, seht die Preise und welche Mengenrabatte
          ihr erhaltet. Stunden können frei für eure eigenen Formate, Grafiken
          und zusätzliche Aufwände eingesetzt werden. Ladet euch euer
          Festpreisangebot herunter oder kontaktiert uns direkt. Beispiel: Ihr
          wählt 4 x Blogartikel, 5 x Social Media Posts und 10 x Story Slides.
          Im oberen Bereich werden euch der Preis sowie euer Rabatt angezeigt.
          Ändert ihr die Anzahl bei den Formaten, werden Preis und Rabatt immer
          angepasst. Werden bei der Umsetzung doch mal andere Formate benötigt,
          werden diese mit gekauften Formaten verrechnet.
        </p>
      </div>
    </div>
    <div class="close-info-mobile" @click="toggleMobileInfoList()">
      <img src="~@/assets/closebutton_1pt.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoMobile",
  computed: {
    opened() {
      return this.$store.state.showInfoMobile;
    },
  },
  methods: {
    toggleMobileInfoList() {
      this.$store.commit("toggleMobileInfo");
    },
    toggleTabletInfoList() {
      this.$store.commit("toggleMobileInfo");
      this.$store.commit("toggleEditorItems");
    },
  },
};
</script>
