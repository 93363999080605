<template>
  <div
    id="carroussel-container"
    class="carroussel-container"
  >
    <svg
      class="background-svg"
      height="100%"
      width="100%"
    >
      <defs>
        <linearGradient
          id="0"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="1"
        >
          <stop
            offset="40%"
            stop-color="#ffffff"
          />
          <stop
            id="animateGradient"
            offset="100%"
            :stop-color="gradientColor"
          />
        </linearGradient>
      </defs>
      <rect
        fill="url(#0)"
        height="100%"
        width="100%"
      />
    </svg>

    <CarrousselHeader v-if="!generatorActive" />


    <div
      v-if="!generatorActive"
      class="carroussel-click-scroll-left"
      @click="scrollLeft()"
    >
      <!-- scroll carroussel to the left / overlay -->
    </div>


    <div
      v-if="!generatorActive"
      id="carroussel-column-container"
      class="carroussel-column-container"
    >
      <CarrousselColumn
        v-for="(carroussel, index) in carroussel"
        :key="index"
        :carroussel="carroussel"
        :chosen="active"
        :offeringset="carroussel.offerings"
        @in-view="selectGradientColor"
        @clicked="toggleGenerator"
      />
    </div>
    <div
      v-if="!generatorActive"
      class="carroussel-click-scroll-right"
      @click="scrollRight()"
    >
      <!-- scroll carroussel to the right / overlay -->
    </div>
    <CarrousselFooter
      v-if="!generatorActive"
      :dots="dots"
      :active="active"
    />

    <div
      v-if="generatorActive"
      id="editor"
      class="editor-container-desktop-mobile"
    >
      <div
        class="info-close-generator"
        @click="toggleGenerator"
      >
        <img src="~@/assets/arrow-left-black.svg">
      </div>
      <InfoMobile v-if="generatorActive" />
      <Info v-if="generatorActive" />
      <DetailModal v-if="generatorActive" />
      <OfferingEdit
        v-if="generatorActive"
        :gradient-color="gradientColor"
        :gradient-angle="gradientAngle"
        @close-generator="toggleGenerator"
      />
      <Items v-if="generatorActive" />
      <ItemsMobile v-if="generatorActive" />
    </div>
  </div>
</template>

<script>
import CarrousselColumn from "./CarrousselColumn.vue";
import CarrousselHeader from "./CarrousselHeader.vue";
import CarrousselFooter from "./CarrousselFooter.vue";

import Info from "../generator/Info.vue";
import DetailModal from "../generator/DetailModal.vue";
import OfferingEdit from "../generator/OfferingEdit.vue";
import Items from "../generator/Items.vue";
import ItemsMobile from "../generator/ItemsMobile.vue";
import InfoMobile from "../generator/InfoMobile.vue";

export default {
  name: "CarrousselContent",
  components: {
    CarrousselColumn,
    CarrousselHeader,
    CarrousselFooter,

    Info,
    DetailModal,
    OfferingEdit,
    Items,
    ItemsMobile,
    InfoMobile,
  },
  data() {
    return {
      translation: {},
      gradientColor: "#ff8900",
      gradientColorPrev: "#ffffff",
      gradientColorCombined: "#ffffff; #ff8900",
      gradientAngle: "",
      dots: this.$store.state.carroussel.length,
      active: 0,
      clicked: false,
      infomobile: this.$store.state.showInfoMobile,
    };
  },
  computed: {
    carroussel() {
      return this.$store.state.carroussel;
    },
    infoShown() {
      return this.$store.state.showInfoMobile;
    },
    generatorActive() {
      return this.$store.state.generatorActive;
    },
    scrollLocked() {
      return this.$store.state.scrollLocked;
    },
    freshAnimate() {
      return this.animate;
    },
  },

  mounted() {
    // window.addEventListener("resize", this.handleResize);

    window.addEventListener("scroll", this.handleScroll);

    this.handleScroll();

    // reset offerings list

    this.$store.commit("resetChosenOfferingsList");
  },

  created() {},

  methods: {

    slideTo(id) {
      let column = document.getElementById("carroussel-column-" + id);
      column.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest"
      });
    },
    scrollLeft() {
      if(this.active === 1) {
        this.slideTo(this.dots)
      } else this.slideTo(this.active - 1)
    },
    scrollRight() {
       if(this.active === this.dots) {
        this.slideTo(1)
      } else this.slideTo(this.active + 1)
    },

    resetCarroussel(id) {
      let elements = document.getElementsByClassName("carroussel-column");
      let center = elements[id];
      center.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    },
    handleResize() {
      if (!this.generatorActive) {
        let elements = document.getElementsByClassName("carroussel-column");
        let center = elements[1];
        if (this.$store.getters.getVisibleChapterMain === "offerings-tab") {
          center.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "center",
          });
        }
      }
    },

    handleScroll() {
      if (!this.generatorActive) {
        const viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;
        let bounding = document
          .getElementById("offerings")
          .getBoundingClientRect();
        if (viewportHeight - bounding.top > 0 && bounding.bottom > 0) {
          this.$store.commit("setVisibleChapterMain", "offerings-tab");
        }
      }
    },

    

    selectGradientColor(id) {
      // set active tab for dots in footer
      this.active = id;

      switch (id % 5) {
        case 1:
          this.gradientColorPrev = this.gradientColor;
          this.gradientColor = "#ff8900";
          this.gradientColorCombined =
            this.gradientColorPrev + "; " + this.gradientColor; // orange
          this.gradientAngle = "0deg";
          console.log(this.gradientColorCombined);

         
          break;

        case 2:
          this.gradientColorPrev = this.gradientColor;
          this.gradientColor = "#651EE3";
          this.gradientColorCombined =
            this.gradientColorPrev + "; " + this.gradientColor; // purple
          this.gradientAngle = "0deg";
          console.log(this.gradientColorCombined);
          break;

        case 3:
          this.gradientColorPrev = this.gradientColor;
          this.gradientColor = "#B9EB5F";
          this.gradientColorCombined =
            this.gradientColorPrev + "; " + this.gradientColor; // green
          this.gradientAngle = "0deg";
          console.log(this.gradientColorCombined);
          break;

        case 4:
          this.gradientColorPrev = this.gradientColor;
          this.gradientColor = "#6EE6F0";
          this.gradientColorCombined =
          this.gradientColorPrev + "; " + this.gradientColor; // blue
          this.gradientAngle = "0deg";
          console.log(this.gradientColorCombined);
          break;

        case 0:
          this.gradientColorPrev = this.gradientColor;
          this.gradientColor = "#F55046";
          this.gradientColorCombined =
            this.gradientColorPrev + "; " + this.gradientColor; // red
          this.gradientAngle = "0deg";
          console.log(this.gradientColorCombined);
          break;

        default:
          this.gradientColorCombined = "#ffffff; #ff8900"; // orange
      }
    },
    toggleGenerator() {
      if (!this.generatorActive) {
        // scroll editor and carroussel into view
        // this.center("offerings");
        // this.center("editor");
        // disable eventListener for focus scrolling
        // window.removeEventListener("resize", this.handleResize);
        // window.removeEventListener("resize", this.handleScroll);
        this.clicked = !this.clicked;
        this.$store.commit("toggleGenerator");
        // block any scrolling on mobile < 490px width
        this.$emit("scrollblock");
      } else {
        this.clicked = !this.clicked;
        // enable eventListener for focus scrolling
        // window.addEventListener("resize", this.handleResize);
        // window.addEventListener("resize", this.handleScroll);
        // block any scrolling
        // this.$emit("scrollblock");
        const root = document.body;
        // const root = document.getElementById("offerings");
        root.classList.remove("scrollblock");
        this.$store.commit("setScrollLock", false);
        this.$store.commit("toggleGenerator");
        console.log("dissolve generator desktop");

        // reset list of chosen offerings
        this.$store.commit("resetChosenOfferingsList");
      }
    },
  },
};
</script>
