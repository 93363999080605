<template>
  <router-view />
</template>

<script>
export default {
  metaInfo: {
    title: "Wirklichkeitsdichte",
    titleTemplate:
      "%s | Agentur für textbasierten Content und wirklichkeitsstiftende Maßnahmen",
  },
  components: {},
  mounted() {
    // Selektiere das SVG-Bild und füge den CSS-Stil "display: none;" hinzu
    const loader = document.querySelector("#loader");
    loader.style.display = "none";
  },
  
};
</script>
