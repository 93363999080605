<template>
  <div class="profile">
    <div class="profile-pic-container">
      <img
        :src="require(`@/assets/team/${profiles.imgurl}`)"
        :alt="altText"
        :title="titleText"
        class="profile-pic"
      >
    </div>
    <div class="profile-text">
      <h1 class="profile-name">
        {{ profiles.name }}
      </h1>
      <div>
        <p class="profile-description">
          {{ profiles.description }}
        </p>
        <p class="profile-job">
          <b>Job:</b> {{ profiles.job }}
        </p>
        <p class="profile-calling">
          <b>Berufung:</b> {{ profiles.calling }}
        </p>
      </div>

      <a
        :href="'mailto:' + profiles.mail"
        class="profile-contact-button"
      >Kontaktieren</a>
    </div>
    <img
      class="profile-arrow"
      src="~@/assets/rightarrow_1pt.svg"
      @click="openFullProfile()"
    >
    <ProfileModalMobile
      :class="{ 'profile-modal-active': active }"
      :profiles="profiles"
      @closeProfileModal="openFullProfile"
    />
  </div>
</template>
<script>
import ProfileModalMobile from "./ProfileModalMobile.vue";
export default {
  components: {
    ProfileModalMobile,
  },
  props: {
    profiles: Object,
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    altText() {
      return this.profiles.alt;
    },
    titleText() {
      return this.profiles.title;
    }
  },
  methods: {
    openFullProfile() {
      this.active = !this.active;
      if (this.active) {
        // When the modal is shown, we want a fixed body
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "initial";
      }
    },
  },
};
</script>
