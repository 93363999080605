<template>
  <div class="call-to-action">
    <div class="cid-element">
      <img src="~@/assets/contact.svg" /><span>Kontaktieren</span>
    </div>
    <div class="cid-element info-icon" @click="toggleInfoMobile">
      <img src="~@/assets/info_2-5pt.svg" /><span>Info</span>
    </div>
    <div class="cid-element" @click="createdPdf()">
      <img src="~@/assets/download.svg" /><span>Speichern</span>
    </div>
  </div>
</template>
<script>
import { PDFDocument, rgb, PageSizes } from "pdf-lib"; // should all be made a mixin!
import fontkit from "@pdf-lib/fontkit";
import download from "downloadjs";
export default {
  name: "CallToAction",
  props: {
    offeringHeader: String,
    offeringsList: Array,
    price: Number,
    discount: Number,
  },
  methods: {
    async createdPdf() {
      /* experimental creation of svg logo with paths

       const svgPath01 = 'M44,35.3c0-0.4,0.1-0.8,0.1-1.2c0-5.1-3.8-9.4-8.8-10c-0.4-0.1-0.8-0.1-1.3-0.1c-5.6,0-10.1,4.5-10.1,10.1 c0,0.4,0,0.8,0.1,1.2c0.6,5,4.9,8.8,10,8.8c0.4,0,0.8,0,1.3-0.1C39.9,43.5,43.5,39.9,44,35.3z M39.9,34.8c-0.3,2.7-2.4,4.8-5.1,5.1 c-0.2,0-0.5,0.1-0.7,0.1c-3,0-5.5-2.2-5.8-5.1c0-0.2-0.1-0.5-0.1-0.7c0-3.2,2.6-5.9,5.9-5.9c0.2,0,0.5,0,0.7,0.1 c2.9,0.4,5.1,2.9,5.1,5.8C39.9,34.3,39.9,34.5,39.9,34.8z'
      const svgPath02 = 'M66,16c-1.5,0-3.1,0.2-4.5,0.6c-2.9,0.8-5.7,2.3-7.9,4.4c-1.5,1.4-2.6,2.9-3.5,4.7c-0.9-1.7-2.1-3.3-3.5-4.7 c-2.2-2.1-5-3.6-7.9-4.4c-1.5-0.4-3-0.6-4.5-0.6C24.1,16,16,24.1,16,34c0,1.5,0.2,3,0.6,4.5c0.8,2.9,2.3,5.7,4.4,7.9 c1.4,1.5,2.9,2.6,4.7,3.5c-1.7,0.9-3.3,2.1-4.7,3.5c-2.1,2.2-3.6,5-4.4,7.9c-0.4,1.5-0.6,3-0.6,4.5C16,75.9,24.1,84,34,84 c1.5,0,3.1-0.2,4.5-0.6c2.9-0.8,5.7-2.3,7.9-4.4c1.5-1.4,2.6-2.9,3.5-4.6c0.9,1.7,2.1,3.3,3.5,4.6c2.2,2.1,5,3.6,7.9,4.4 c1.5,0.4,3,0.6,4.5,0.6C75.9,84,84,75.9,84,66c0-1.5-0.2-3-0.6-4.5c-0.8-2.9-2.3-5.7-4.4-7.9c-1.4-1.5-2.9-2.6-4.6-3.5 c1.7-0.9,3.3-2.1,4.6-3.5c2.1-2.2,3.6-5,4.4-7.9c0.4-1.5,0.6-3,0.6-4.5C84,24.1,75.9,16,66,16z M50,57.5c-0.8-1.5-1.7-2.8-2.8-4 c-0.2-0.2-0.5-0.5-0.7-0.7c-1.2-1.1-2.6-2.1-4-2.8c1.5-0.8,2.8-1.7,4-2.8c0.2-0.2,0.5-0.5,0.7-0.7c1.1-1.2,2.1-2.6,2.8-4 c0.8,1.5,1.7,2.8,2.8,4c0.2,0.2,0.5,0.5,0.7,0.7c1.2,1.1,2.6,2.1,4,2.8c-1.4,0.8-2.8,1.7-4,2.8c-0.2,0.2-0.5,0.5-0.7,0.7 C51.7,54.7,50.8,56.1,50,57.5z M24,43.6c-1.6-1.7-2.8-3.8-3.4-6.1c-0.3-1.1-0.4-2.3-0.4-3.5c0-7.6,6.2-13.9,13.8-13.9 c1.2,0,2.3,0.1,3.5,0.4c2.3,0.6,4.3,1.7,6.1,3.4c2.8,2.6,4.3,6.2,4.3,10.1c0,1.2-0.1,2.3-0.4,3.5c-0.1,0.2-0.1,0.4-0.2,0.6'
      page.moveTo(100, page.getHeight() - 5)
      page.moveDown(125)
      page.drawSvgPath(svgPath01)
      page.drawSvgPath(svgPath02)

      */

      // setup page
      const pdfDoc = await PDFDocument.create();
      pdfDoc.registerFontkit(fontkit);
      const page = pdfDoc.addPage(PageSizes.A4);

      const marginTop = page.getHeight() - 36;
      // const marginBottom = 36;
      const marginLeft = 36;
      const marginRight = page.getWidth() - 36;

      console.log("page size:" + page.getWidth() + " x " + page.getHeight());

      //loading custom fonts
      /* REGULAR */
      const AcuminRegularFont = require("@/assets/Acumin-RPro.otf");
      const AcuminRegularFontBytes = await fetch(AcuminRegularFont).then(
        (res) => res.arrayBuffer()
      );
      const AcuminRegular = await pdfDoc.embedFont(AcuminRegularFontBytes);

      /* BOLD */

      const AcuminBoldFont = require("@/assets/Acumin-BdPro.otf");
      const AcuminBoldFontBytes = await fetch(AcuminBoldFont).then((res) =>
        res.arrayBuffer()
      );
      const AcuminBold = await pdfDoc.embedFont(AcuminBoldFontBytes);

      /* ITALICS */

      //const AcuminItalicFont = require("@/assets/Acumin-ItPro.otf");
      //const AcuminItalicFontBytes = await fetch(AcuminItalicFont).then((res) =>
      // res.arrayBuffer()
      // );
      // const AcuminItalic = await pdfDoc.embedFont(AcuminItalicFontBytes);

      // font sizes
      const fontSizeHeader = 8;
      // const fontSizeListHeader = 16;
      const fontSizeList = 8;
      const fontSizeAdress = 8;

      // header logo with typography
      const logo = require("@/assets/logo.png");
      const logoBytes = await fetch(logo).then((res) => res.arrayBuffer());
      const pngLogo = await pdfDoc.embedPng(logoBytes);
      const logoDims = pngLogo.scale(0.29);
      const headerText = "WIRKLICHKEITSDICHTE";
      const headerWidth = AcuminRegular.widthOfTextAtSize(
        headerText,
        fontSizeHeader
      );

      // draw logo
      page.drawImage(pngLogo, {
        x: page.getWidth() / 2 - logoDims.width / 2,
        y: marginTop - logoDims.height,
        width: logoDims.width,
        height: logoDims.height,
      });

      // draw header text
      page.drawText(headerText, {
        x: page.getWidth() / 2 - headerWidth / 2,
        y: page.getHeight() - 36 - logoDims.height - 18,
        size: fontSizeHeader,
        font: AcuminBold,
        color: rgb(0, 0, 0),
      });

      // draw top separator

      page.drawLine({
        start: { x: marginLeft, y: page.getHeight() - 107 },
        end: { x: marginRight, y: page.getHeight() - 107 },
        thickness: 0.5,
        color: rgb(0.6, 0.6, 0.6),
        opacity: 1,
      });

      // draw offer header

      page.drawText("A N G E B O T", {
        x: marginLeft,
        y: page.getHeight() - 168,
        size: 24,
        font: AcuminBold,
        spacing: 100,
        color: rgb(0, 0, 0),
      });

      // company adress

      page.drawText("WIRKLICHKEITSDICHTE GMBH", {
        x: 392,
        y: page.getHeight() - 168,
        size: fontSizeAdress,
        font: AcuminBold,
        color: rgb(0, 0, 0),
      });

      page.drawText("EXERZIERSTRASSE 14", {
        x: 392,
        y: page.getHeight() - 168 - 12,
        size: fontSizeAdress,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      page.drawText("13357 BERLIN", {
        x: 392,
        y: page.getHeight() - 168 - 12 * 2,
        size: fontSizeAdress,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
        lineHeight: 16,
      });

      page.drawText("www.wirklichkeitsdichte.org", {
        x: 392,
        y: page.getHeight() - 168 - 12 * 5,
        size: fontSizeAdress,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      // draw experience name
      page.drawText(this.offeringHeader, {
        x: marginLeft,
        y: page.getHeight() - 312,
        size: 24,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      page.drawText("Experience", {
        x: marginLeft,
        y: page.getHeight() - 327,
        size: 8,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      // date

      let date = new Date();
      const dateHeader =
        date.getDate() + "." + date.getMonth() + "." + date.getFullYear();

      page.drawText(dateHeader, {
        x: 392,
        y: page.getHeight() - 312,
        size: 8,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      // draw center separator

      page.drawLine({
        start: { x: marginLeft, y: page.getHeight() - 342 },
        end: { x: marginRight, y: page.getHeight() - 342 },
        thickness: 0.5,
        color: rgb(0.6, 0.6, 0.6),
        opacity: 1,
      });

      // create list with chosen offerings

      let yPositionColumnHeader = 385;
      let yPositionList = 405;

      // helper function for centering
      function centerText(string, font, fontSize, fieldWidth) {
        return fieldWidth / 2 - font.widthOfTextAtSize(string, fontSize) / 2;
      }

      // table column names

      page.drawText("Pos.", {
        x: marginLeft + centerText("Pos.", AcuminBold, fontSizeList, 26),
        y: page.getHeight() - yPositionColumnHeader,
        size: fontSizeList,
        font: AcuminBold,
        color: rgb(0, 0, 0),
      });

      page.drawText("Bezeichnung", {
        x:
          marginLeft +
          26 +
          centerText("Bezeichnung", AcuminBold, fontSizeList, 264),
        y: page.getHeight() - yPositionColumnHeader,
        size: fontSizeList,
        font: AcuminBold,
        color: rgb(0, 0, 0),
      });

      page.drawText("Menge", {
        x:
          marginLeft +
          26 +
          264 +
          centerText("Menge", AcuminBold, fontSizeList, 46),
        y: page.getHeight() - yPositionColumnHeader,
        size: fontSizeList,
        font: AcuminBold,
        color: rgb(0, 0, 0),
      });

      page.drawText("Einheit", {
        x:
          marginLeft +
          26 +
          264 +
          46 +
          centerText("Einheit", AcuminBold, fontSizeList, 46),
        y: page.getHeight() - yPositionColumnHeader,
        size: fontSizeList,
        font: AcuminBold,
        color: rgb(0, 0, 0),
      });

      page.drawText("Einzel €", {
        x:
          marginLeft +
          26 +
          264 +
          46 +
          46 +
          centerText("Einzel €", AcuminBold, fontSizeList, 64),
        y: page.getHeight() - yPositionColumnHeader,
        size: fontSizeList,
        font: AcuminBold,
        color: rgb(0, 0, 0),
      });

      page.drawText("Gesamt €", {
        x:
          marginLeft +
          26 +
          264 +
          46 +
          46 +
          64 +
          centerText("Gesamt €", AcuminBold, fontSizeList, 78),
        y: page.getHeight() - yPositionColumnHeader,
        size: fontSizeList,
        font: AcuminBold,
        color: rgb(0, 0, 0),
      });

      // generate list

      // top line

      const topEdge = 393;

      page.drawLine({
        start: { x: marginLeft, y: page.getHeight() - topEdge },
        end: { x: marginRight, y: page.getHeight() - topEdge },
        thickness: 0.5,
        color: rgb(0.6, 0.6, 0.6),
        opacity: 1,
      });

      let lineOffset = 413;

      for (let i = 0; i < this.offeringsList.length; i++) {
        // offering index
        page.drawText((i + 1).toString(), {
          x:
            marginLeft +
            centerText((i + 1).toString(), AcuminRegular, fontSizeList, 26),
          y: page.getHeight() - yPositionList,
          size: fontSizeList,
          font: AcuminRegular,
          color: rgb(0, 0, 0),
        });

        // offering names
        page.drawText(this.offeringsList[i].name, {
          x: marginLeft + 26 + 4,
          y: page.getHeight() - yPositionList,
          size: fontSizeList,
          font: AcuminRegular,
          color: rgb(0, 0, 0),
        });

        // offering quantity
        page.drawText(this.offeringsList[i].quantity.toString(), {
          x:
            marginLeft +
            26 +
            264 +
            centerText(
              this.offeringsList[i].quantity.toString(),
              AcuminRegular,
              fontSizeList,
              46
            ),
          y: page.getHeight() - yPositionList,
          size: fontSizeList,
          font: AcuminRegular,
          color: rgb(0, 0, 0),
        });

        // offering unit
        page.drawText("Stück", {
          x:
            marginLeft +
            26 +
            264 +
            46 +
            centerText("Stück", AcuminRegular, fontSizeList, 46),
          y: page.getHeight() - yPositionList,
          size: fontSizeList,
          font: AcuminRegular,
          color: rgb(0, 0, 0),
        });

        // offering price
        page.drawText(this.offeringsList[i].price.toString() + "€", {
          x:
            marginLeft +
            26 +
            264 +
            46 +
            46 +
            centerText(
              this.offeringsList[i].price.toString() + "€",
              AcuminRegular,
              fontSizeList,
              64
            ),
          y: page.getHeight() - yPositionList,
          size: fontSizeList,
          font: AcuminRegular,
          color: rgb(0, 0, 0),
        });

        // offering total
        page.drawText(
          (
            this.offeringsList[i].price * this.offeringsList[i].quantity
          ).toString() + "€",
          {
            x:
              marginLeft +
              26 +
              264 +
              46 +
              46 +
              64 +
              centerText(
                (
                  this.offeringsList[i].price * this.offeringsList[i].quantity
                ).toString() + "€",
                AcuminBold,
                fontSizeList,
                78
              ),
            y: page.getHeight() - yPositionList,
            size: fontSizeList,
            font: AcuminRegular,
            color: rgb(0, 0, 0),
          }
        );

        page.drawLine({
          start: { x: marginLeft, y: page.getHeight() - lineOffset },
          end: { x: marginRight, y: page.getHeight() - lineOffset },
          thickness: 0.5,
          color: rgb(0.6, 0.6, 0.6),
          opacity: 1,
        });

        // increment position for next item in list
        yPositionList = yPositionList + 20;
        lineOffset += 20;
      }

      // left outer line
      page.drawLine({
        start: { x: marginLeft, y: page.getHeight() - topEdge },
        end: { x: marginLeft, y: page.getHeight() - (lineOffset + 90) },
        thickness: 0.5,
        color: rgb(0.6, 0.6, 0.6),
        opacity: 1,
      });

      // right outer line
      page.drawLine({
        start: { x: marginRight, y: page.getHeight() - topEdge },
        end: { x: marginRight, y: page.getHeight() - (lineOffset + 90) },
        thickness: 0.5,
        color: rgb(0.6, 0.6, 0.6),
        opacity: 1,
      });

      // all the lines inbetween
      page.drawLine({
        start: { x: marginLeft + 26, y: page.getHeight() - topEdge },
        end: { x: marginLeft + 26, y: page.getHeight() - lineOffset + 20 },
        thickness: 0.5,
        color: rgb(0.6, 0.6, 0.6),
        opacity: 1,
      });

      page.drawLine({
        start: { x: marginLeft + 26 + 264, y: page.getHeight() - topEdge },
        end: {
          x: marginLeft + 26 + 264,
          y: page.getHeight() - lineOffset + 20,
        },
        thickness: 0.5,
        color: rgb(0.6, 0.6, 0.6),
        opacity: 1,
      });

      page.drawLine({
        start: { x: marginLeft + 26 + 264 + 46, y: page.getHeight() - topEdge },
        end: {
          x: marginLeft + 26 + 264 + 46,
          y: page.getHeight() - lineOffset + 20,
        },
        thickness: 0.5,
        color: rgb(0.6, 0.6, 0.6),
        opacity: 1,
      });

      page.drawLine({
        start: {
          x: marginLeft + 26 + 264 + 46 + 46,
          y: page.getHeight() - topEdge,
        },
        end: {
          x: marginLeft + 26 + 264 + 46 + 46,
          y: page.getHeight() - lineOffset + 20,
        },
        thickness: 0.5,
        color: rgb(0.6, 0.6, 0.6),
        opacity: 1,
      });

      page.drawLine({
        start: {
          x: marginLeft + 26 + 264 + 46 + 46 + 64,
          y: page.getHeight() - topEdge,
        },
        end: {
          x: marginLeft + 26 + 264 + 46 + 46 + 64,
          y: page.getHeight() - (lineOffset + 90),
        },
        thickness: 0.5,
        color: rgb(0.6, 0.6, 0.6),
        opacity: 1,
      });

      // total and discount

      // helper function for prices
      function toCurrencyString(number) {
        return new Intl.NumberFormat("de-DE", { maximumFractionDigits: 2 })
          .format(number)
          .toString();
      }

      page.drawText("Zwischensumme (netto): ", {
        x: marginLeft + 26 + 264 + 10,
        y: page.getHeight() - lineOffset,
        size: fontSizeList,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      page.drawText(toCurrencyString(this.price) + "€", {
        x:
          marginLeft +
          446 +
          centerText(
            toCurrencyString(this.price) + "€",
            AcuminBold,
            fontSizeList,
            78
          ),
        y: page.getHeight() - lineOffset,
        size: fontSizeList,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      page.drawText("abzüglich Rabatt: ", {
        x: marginLeft + 26 + 264 + 10,
        y: page.getHeight() - (lineOffset + 20),
        size: fontSizeList,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      page.drawText("- " + toCurrencyString(this.discount) + "€", {
        x:
          marginLeft +
          446 +
          centerText(
            "- " + toCurrencyString(this.discount) + "€",
            AcuminBold,
            fontSizeList,
            78
          ),
        y: page.getHeight() - (lineOffset + 20),
        size: fontSizeList,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      page.drawText("gesamt (netto): ", {
        x: marginLeft + 26 + 264 + 10,
        y: page.getHeight() - (lineOffset + 40),
        size: fontSizeList,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      page.drawText(toCurrencyString(this.price - this.discount) + "€", {
        x:
          marginLeft +
          446 +
          centerText(
            toCurrencyString(this.price - this.discount) + "€",
            AcuminBold,
            fontSizeList,
            78
          ),
        y: page.getHeight() - (lineOffset + 40),
        size: fontSizeList,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      page.drawText("Umsatzsteuer 19%: ", {
        x: marginLeft + 26 + 264 + 10,
        y: page.getHeight() - (lineOffset + 60),
        size: fontSizeList,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      page.drawText(
        toCurrencyString((this.price - this.discount) * 0.19) + "€",
        {
          x:
            marginLeft +
            446 +
            centerText(
              toCurrencyString((this.price - this.discount) * 0.19) + "€",
              AcuminBold,
              fontSizeList,
              78
            ),
          y: page.getHeight() - (lineOffset + 60),
          size: fontSizeList,
          font: AcuminRegular,
          color: rgb(0, 0, 0),
        }
      );

      page.drawLine({
        start: {
          x: marginLeft + 26 + 264,
          y: page.getHeight() - (lineOffset + 66),
        },
        end: { x: marginRight, y: page.getHeight() - (lineOffset + 66) },
        thickness: 0.5,
        color: rgb(0.6, 0.6, 0.6),
        opacity: 1,
      });

      page.drawText("Gesamtbetrag: ", {
        x: marginLeft + 26 + 264 + 10,
        y: page.getHeight() - (lineOffset + 80),
        size: fontSizeList,
        font: AcuminBold,
        color: rgb(0, 0, 0),
      });

      page.drawText(
        toCurrencyString(
          (this.price - this.discount) * 0.19 + (this.price - this.discount)
        ) + "€",
        {
          x:
            marginLeft +
            446 +
            centerText(
              toCurrencyString(
                (this.price - this.discount) * 0.19 +
                  (this.price - this.discount)
              ) + "€",
              AcuminBold,
              fontSizeList,
              78
            ),
          y: page.getHeight() - (lineOffset + 80),
          size: fontSizeList,
          font: AcuminBold,
          color: rgb(0, 0, 0),
        }
      );

      // bottom line table

      page.drawLine({
        start: { x: marginLeft, y: page.getHeight() - (lineOffset + 90) },
        end: { x: marginRight, y: page.getHeight() - (lineOffset + 90) },
        thickness: 0.5,
        color: rgb(0.6, 0.6, 0.6),
        opacity: 1,
      });

      // bottom line page

      page.drawLine({
        start: { x: marginLeft, y: page.getHeight() - 720 },
        end: { x: marginRight, y: page.getHeight() - 720 },
        thickness: 0.5,
        color: rgb(0.6, 0.6, 0.6),
        opacity: 1,
      });

      // footer

      // column #1

      // CEO

      page.drawText("Geschäftsführer", {
        x: marginLeft,
        y: page.getHeight() - 742,
        size: fontSizeList,
        font: AcuminBold,
        color: rgb(0, 0, 0),
      });

      page.drawText("Peter Wagner", {
        x: marginLeft,
        y: page.getHeight() - 754,
        size: fontSizeList,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      // contact

      page.drawText("Kontakt", {
        x: marginLeft,
        y: page.getHeight() - 778,
        size: fontSizeList,
        font: AcuminBold,
        color: rgb(0, 0, 0),
      });

      page.drawText("+49 157 322 322 91", {
        x: marginLeft,
        y: page.getHeight() - 790,
        size: fontSizeList,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      page.drawText("peter.wagner@wirklichkeitsdichte.org", {
        x: marginLeft,
        y: page.getHeight() - 802,
        size: fontSizeList,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      // column #2

      // banking account

      page.drawText("Bankverbindung", {
        x: marginLeft + page.getWidth() / 3,
        y: page.getHeight() - 742,
        size: fontSizeList,
        font: AcuminBold,
        color: rgb(0, 0, 0),
      });

      page.drawText("IBAN: DE 07 1005 0000 0191 0834 29", {
        x: marginLeft + page.getWidth() / 3,
        y: page.getHeight() - 754,
        size: fontSizeList,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      // contact

      page.drawText("Geschäftsadresse", {
        x: marginLeft + page.getWidth() / 3,
        y: page.getHeight() - 778,
        size: fontSizeList,
        font: AcuminBold,
        color: rgb(0, 0, 0),
      });

      page.drawText("Wirklichkeitsdichte GmbH", {
        x: marginLeft + page.getWidth() / 3,
        y: page.getHeight() - 790,
        size: fontSizeList,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      page.drawText("Exerzierstraße 14, 13357 Berlin", {
        x: marginLeft + page.getWidth() / 3,
        y: page.getHeight() - 802,
        size: fontSizeList,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      // column #3

      // registration

      page.drawText("Handelsregister", {
        x: marginLeft + 404,
        y: page.getHeight() - 742,
        size: fontSizeList,
        font: AcuminBold,
        color: rgb(0, 0, 0),
      });

      page.drawText("235923 B", {
        x: marginLeft + 404,
        y: page.getHeight() - 754,
        size: fontSizeList,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      // tax id

      page.drawText("Steuernummern", {
        x: marginLeft + 404,
        y: page.getHeight() - 778,
        size: fontSizeList,
        font: AcuminBold,
        color: rgb(0, 0, 0),
      });

      page.drawText("USt-Id: DE 350 876 019", {
        x: marginLeft + 404,
        y: page.getHeight() - 790,
        size: fontSizeList,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      page.drawText("Steuernummer: 30 / 594 / 503 66", {
        x: marginLeft + 404,
        y: page.getHeight() - 802,
        size: fontSizeList,
        font: AcuminRegular,
        color: rgb(0, 0, 0),
      });

      // export pdf
      const pdfBytes = await pdfDoc.save();

      // Trigger the browser to download the PDF document
      const today =
        date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();
      download(
        pdfBytes,
        "Angebot_Wirklichkeitsdichte_" + today + ".pdf",
        "application/pdf"
      );
    },
    toggleInfoMobile() {
      this.$store.commit("toggleMobileInfo");
      this.$store.commit("toggleEditorItems");
    },
  },
};
</script>
