<template>
  <div
    id="clients"
    ref="container"
    class="client-container"
  >
    <h1 class="main-section-header">
      Clients
    </h1>
    <ClientRow
      v-for="(clients, index) in clients"
      :key="index"
      :clients="clients"
    />
  </div>
</template>
<script>
import ClientRow from "./ClientRow.vue";

export default {
  name: "ClientContent",
  components: {
    ClientRow,
  },

  data() {
    return {
      button: true,
    };
  },
  computed: {
    clients() {
      return this.$store.state.clients;
    },
    expand() {
      return this.expanded;
    },
    generatorActive() {
      return this.$store.state.generatorActive;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (!this.generatorActive) {
        const viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;
        let bounding = document
          .getElementById("clients")
          .getBoundingClientRect();
        if (viewportHeight - bounding.top > 0 && bounding.bottom > 0) {
          this.$store.commit("setVisibleChapterMain", "clients-tab");
        }
      }
    },
  },
};
</script>
