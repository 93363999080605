<template>
  <div
    :id="'carroussel-column-' + carroussel.id"
    class="carroussel-column"
    :class="{ 'carroussel-column-active': active }"
  >
    <div class="carroussel-column-header">
      <h1>{{ carroussel.name }}</h1>
      <h3>Experience</h3>
    </div>
    <div class="carroussel-price">
      <span>Basispreis</span>
      <span class="carroussel-price-amount">{{ toCurrencyString(Math.ceil(basePrice)) }} €</span>
    </div>
    <div class="carroussel-description">
      {{ carroussel.description }}
    </div>

    <div
      class="caroussel-config-button"
      @click="$emit('clicked', carroussel.name), setItems()"
    >
      Konfigurieren
    </div>
  </div>
</template>

<script>

export default {
  name: "CarrousselColumn",

  props: {
    carroussel: Object,
    chosen: Number,
  },

  data() {
    return {
      active: false,
      basePrice: 0,
    };
  },
  computed: {
  },
  mounted() {

    document
      .getElementById("carroussel-column-container")
      .addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },

  created() {
    this.calcBasePrice();
  },

  methods: {
    resetCarroussel(id) {
      let elements = document.getElementsByClassName("carroussel-column");
      let center = elements[id];
      center.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    },

    toCurrencyString(number) {
        return new Intl.NumberFormat("de-DE", {  maximumFractionDigits: 0 })
          .format(number)
          .toString();
      },

    calcBasePrice() {
      console.log("discount column: " + this.calculateDiscount());
      this.basePrice = this.$store.getters.getBasePriceColumn(this.carroussel.offerings);
      this.basePrice -= this.calculateDiscount();

    },
    calculateDiscount() {
        let discount = 0;
        let quantityList = {};
        let columnOfferings = this.$store.getters.getColumnOfferings(this.carroussel.offerings);
  
        columnOfferings.map((offering) => {
          quantityList[offering.category] = offering.quantity;
        });
  
        // blogposts
        if (quantityList.blogpost) {
          if (quantityList.blogpost > 1 && quantityList.blogpost <= 3) {
            discount += quantityList.blogpost * 25;
          }
          if (quantityList.blogpost > 3 && quantityList.blogpost <= 5) {
            discount += quantityList.blogpost * 50;
          }
          if (quantityList.blogpost > 5 && quantityList.blogpost <= 8) {
            discount += quantityList.blogpost * 75;
          }
          if (quantityList.blogpost > 8) {
            discount += quantityList.blogpost * 100;
          }
        }
  
        // deep dive
        if (quantityList.deepdive) {
          if (quantityList.deepdive == 2) {
            discount += quantityList.deepdive * 50;
          }
          if (quantityList.deepdive == 3) {
            discount += quantityList.deepdive * 65;
          }
          if (quantityList.deepdive > 3 && quantityList.deepdive < 8) {
            discount += quantityList.deepdive * 100;
          }
          if (quantityList.deepdive >= 9) {
            discount += quantityList.deepdive * 125;
          }
        }
  
        // social post
        if (quantityList.socialpost) {
          
          if (quantityList.socialpost >= 5) {
            discount += Math.floor(quantityList.socialpost / 5 ) * 137.5;
           
          }
        }
  
        // story post
        if (quantityList.storypost) {
          if (quantityList.storypost >= 5 && quantityList.storypost < 10) {
            discount += quantityList.storypost * 7.5;
          }
          if (quantityList.storypost >= 10) {
            discount += quantityList.storypost * 10;
          }
        }
  
        // consulting has no discount
      
        // pagetext
        if (quantityList.pagetext) {
          if (quantityList.pagetext > 1 && quantityList.pagetext <= 3) {
            discount += quantityList.pagetext * 25;
          }
          if (quantityList.pagetext > 3 && quantityList.pagetext <= 5) {
            discount += quantityList.pagetext * 50;
          }
          if (quantityList.pagetext > 5 && quantityList.pagetext <= 8) {
            discount += quantityList.pagetext * 75;
          }

          if (quantityList.pagetext > 8) {
            discount += quantityList.pagetext * 100;
          }
        }
  
        // voice over
        if (quantityList.voiceover) {
          if (quantityList.voiceover >= 4) {
            discount += quantityList.voiceover * 50;
          }
        }
  
        // script erklärfilm
        if (quantityList.explainer) {
          if (quantityList.explainer == 4 || quantityList.explainer == 3) { 
            discount += quantityList.explainer * 75;
          }
          if (quantityList.explainer > 4 && quantityList.explainer <= 8) {
            discount += quantityList.explainer * 100;
          }
          if (quantityList.explainer > 8) {
            discount += quantityList.explainer * 125;
          }
        }
  
        
  
        // additional hours
        if (quantityList.hours) {
          if (quantityList.hours >= 10 && quantityList.hours < 20) {
            discount += quantityList.hours * 11.825;
          }
          if (quantityList.hours >= 20) {
            discount += quantityList.hours * 26.015;
          }
        }
        return discount;
      },
    setItems() {
      // set the right header for editor
      this.$store.commit("setChosenCarrousselColumn", this.carroussel.name);

      // set the right amount of items in chosen base offering
      for (let i = 0; i < this.carroussel.offerings.length; i++) {
        this.$store.commit("addToList", this.carroussel.offerings[i].id); // check item
        this.$store.commit("enterQuantity", {
          id: this.carroussel.offerings[i].id,
          amount: this.carroussel.offerings[i].quantity,
        }); // set amount
      }
    },
    handleScroll() {
      const viewportWidth =
        window.innerWidth || document.documentElement.clientWidth;
      let id = "carroussel-column-" + this.carroussel.id;

      let bounding = document.getElementById(id).getBoundingClientRect();
      let viewportCenter = viewportWidth / 2;
      if (bounding.left < viewportCenter && bounding.right > viewportCenter) {
        this.active = true;
        this.$emit("in-view", this.carroussel.id);
      } else {
        this.active = false;
      }
    },
  },
};
</script>
