<template>
  <div
    id="landing-animation-container"
    class="landing-animation-container"
    @click="EXIT_CAMERA()"
  ></div>
</template>
<script>
import acumin from "@/assets/AcuminProRegular.json";
import {
  Scene,
  TrackballControls,
  PerspectiveCamera,
  WebGLRenderer,
  Color,
  FogExp2,
  // CylinderBufferGeometry,
  MeshPhongMaterial,
  Mesh,
  DirectionalLight,
  AmbientLight,
  // LineBasicMaterial,
  // Geometry,
  Vector2,
  Vector3,
  //Quaternion,
  // Line,
  FontLoader,
  TextGeometry,
  // GeometryUtils,
  // QuaternionKeyframeTrack,
  VectorKeyframeTrack,
  AnimationClip,
  AnimationMixer,
  EffectComposer,
  RenderPass,
  BokehPass,
  UnrealBloomPass,
  // ShaderPass,
  // BokehShader
  // DepthTexture,
  // WebGLRenderTarget,
} from "three-full";

import TWEEN from "tween.js";

export default {
  name: "BackgroundAnimation",
  components: {},
  props: {
    mousecoords: Object,
    opacity: Number,
  },
  data() {
    return {
      height: 0,
      width: 0,
      camera: null,
      controls: null,
      scene: null,
      renderer: null,
      postprocessing: {},
      acumin: acumin,
      mixer: null,
      mixerO: null,
      mixerI: null,
      mixerN: null,
      mixerBang: null,
      //axisLines: [],
      letters: [],
      bokehPass: null,
      renderPass: null,
      exiting: false,
      mouse: this.mousecoords,
    };
  },
  mounted() {
    this.INIT(this.$el.offsetWidth, this.$el.offsetHeight, this.$el);
    this.ANIMATE_CAMERA(); // Call the camera animation function
    this.ANIMATE();
    this.RESIZE(this.$el.offsetWidth, this.$el.offsetHeight);
    // this.$el.addEventListener("mousemove", this.onDocumentMouseMove(), false);
  },
  computed: {},
  watch: {
    mouse() {
      console.log("hello");
    },
  },
  methods: {
    SET_VIEWPORT_SIZE(width, height) {
      this.width = width;
      this.height = height;
    },

    INITIALIZE_RENDERER(el) {
      this.renderer = new WebGLRenderer({ antialias: true });
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(this.width, this.height);
      this.renderer.gammaInput = true;
      this.renderer.gammaOutput = true;
      this.renderer.autoClear = false;

      el.appendChild(this.renderer.domElement);
    },

    INITIALIZE_POSTPROCESSING() {
      // Set the camera's depth texture to the new depth texture
      console.log(this.camera.depthTexture);

      const composer = new EffectComposer(this.renderer);
      this.renderPass = new RenderPass(this.scene, this.camera);

      const bloomPass = new UnrealBloomPass(
        new Vector2(window.innerWidth, window.innerHeight),
        0.1,
        0.5,
        1.0
      );
      bloomPass.exposure = 1.2;
      bloomPass.threshold = 0;
      bloomPass.strength = 0.05;
      bloomPass.radius = 1;

      this.bokehPass = new BokehPass(this.scene, this.camera, {
        focus: 80,
        aperture: 0.05,
        maxblur: 0.008,
        width: this.width,
        height: this.height,
      });
      this.bokehPass.renderToScreen = false;

      composer.addPass(this.renderPass);
      composer.addPass(bloomPass);
      composer.addPass(this.bokehPass);

      this.postprocessing.composer = composer;
    },

    INITIALIZE_CAMERA() {
      this.camera = new PerspectiveCamera(
        // 1. Field of View (degrees)
        100,
        // 2. Aspect ratio
        this.width / this.height,
        // 3. Near clipping plane
        0.1,
        // 4. Far clipping plane
        1000
      );
      this.camera.position.z = 135;
    },

    INITIALIZE_CONTROLS() {
      this.controls = new TrackballControls(
        this.camera,
        this.renderer.domElement
      );
      this.controls.rotateSpeed = 1.5;
      this.controls.zoomSpeed = 5;
      this.controls.panSpeed = 0.8;
      this.controls.noZoom = false;
      this.controls.noPan = true;
      this.controls.staticMoving = true;
      this.controls.dynamicDampingFactor = 0.3;
      this.controls.target = new Vector3(0, 0, 0);
    },

    INITIALIZE_SCENE() {
      this.scene = new Scene();
      this.scene.background = new Color(0xffffff);
      this.scene.fog = new FogExp2(0xffffff, 0.004);

      var fontloader = new FontLoader();

      let font = fontloader.parse(acumin);

      function letterMesh(symbol, font, size, segments) {
        const geometry = new TextGeometry(symbol, {
          font: font,
          size: size,
          height: 0,
          curveSegments: segments,
        });
        let material = new MeshPhongMaterial({
          color: 0xff0000,
          flatShading: false,
        });

        let mesh = new Mesh(geometry, material);
        mesh.matrixAutoUpdate = true;
        return mesh;
      }

      function poseMesh(mesh, x, y, z) {
        mesh.geometry.center();
        mesh.position.x = x;
        mesh.position.y = y;
        mesh.position.z = z;
      }

      function rotateMesh(mesh, xDeg, yDeg, zDeg) {
        mesh.geometry.center();
        mesh.rotation.x = xDeg;
        mesh.rotation.y = yDeg;
        mesh.rotation.z = zDeg;
      }

      /* function rotateQuaternion(mesh, xDeg, yDeg, zDeg) {
        let meshCopy = structuredClone(mesh);
        rotateMesh(meshCopy, xDeg, yDeg, zDeg);
        return meshCopy.quaternion;
      } */

      let M = letterMesh("M", font, 80, 6);
      poseMesh(M, -100, 70, 0);
      rotateMesh(M, -0.2, -0.3, 0.6);

      let o = letterMesh("o", font, 80, 6);
      poseMesh(o, -30, 30, 60);
      rotateMesh(o, 0.2, 0.1, 0);

      let I = letterMesh("i", font, 80, 6);
      poseMesh(I, 0, 0, -10);
      rotateMesh(I, 0.4, -0.5, 0.5);

      let n = letterMesh("n", font, 80, 6);
      poseMesh(n, 50, 0, -70);
      rotateMesh(n, -0.3, 0.5, 0.1);

      let bang = letterMesh("!", font, 80, 6);
      poseMesh(bang, 130, -10, -180);
      rotateMesh(bang, -0.3, 0.4, -0.4);

      this.letters.push(M);
      this.letters.push(o);
      this.letters.push(I);
      this.letters.push(n);
      this.letters.push(bang);
      this.scene.add(...this.letters);

      // animation testing

      // rotation animation with tweenjs

      function rotateBackAndForth(deg, duration, mesh) {
        let rotationAmount = (deg * Math.PI) / 180; // convert 20 degrees to radians

        // Create a Tween that rotates the mesh to the left by the desired amount
        let tween1 = new TWEEN.Tween(mesh.rotation)
          .to({ y: -rotationAmount }, duration)
          .easing(TWEEN.Easing.Quadratic.In);

        // Create a second Tween that rotates the mesh back to its original position
        let tween2 = new TWEEN.Tween(mesh.rotation)
          .to({ y: 0 }, duration)
          .easing(TWEEN.Easing.Quadratic.In);

        // Chain the two Tweens together to create a continuous animation
        tween1.chain(tween2);
        tween2.chain(tween1);

        return tween1;
      }

      let tween0 = rotateBackAndForth(20, 14000, M);
      let tween1 = rotateBackAndForth(10, 4000, o);
      let tween2 = rotateBackAndForth(5, 3000, I);
      let tween3 = rotateBackAndForth(4, 2000, n);
      let tween4 = rotateBackAndForth(15, 14000, bang);
      tween0.start();
      tween1.start();
      tween2.start();
      tween3.start();
      tween4.start();

      // Create an animation clip that moves the cube back and forth along the x-axis
      const positionKFM = new VectorKeyframeTrack(
        ".position",
        [0, 1, 2],
        [
          M.position.x,
          M.position.y,
          M.position.z,
          M.position.x,
          M.position.y + 10,
          M.position.z,
          M.position.x,
          M.position.y,
          M.position.z,
        ]
      );

      const positionKFo = new VectorKeyframeTrack(
        ".position",
        [0, 1, 2],
        [
          o.position.x,
          o.position.y,
          o.position.z,
          o.position.x + 5,
          o.position.y - 20,
          o.position.z,
          o.position.x,
          o.position.y,
          o.position.z,
        ]
      );

      const positionKFi = new VectorKeyframeTrack(
        ".position",
        [0, 1, 2],
        [
          I.position.x,
          I.position.y,
          I.position.z,
          I.position.x - 5,
          I.position.y - 15,
          I.position.z + 10,
          I.position.x,
          I.position.y,
          I.position.z,
        ]
      );

      const positionKFn = new VectorKeyframeTrack(
        ".position",
        [0, 1, 2],
        [
          n.position.x,
          n.position.y,
          n.position.z,
          n.position.x - 12,
          n.position.y - 12,
          n.position.z - 12,
          n.position.x,
          n.position.y,
          n.position.z,
        ]
      );

      const positionKFbang = new VectorKeyframeTrack(
        ".position",
        [0, 1, 2],
        [
          bang.position.x,
          bang.position.y,
          bang.position.z,
          bang.position.x - 14,
          bang.position.y,
          bang.position.z,
          bang.position.x,
          bang.position.y,
          bang.position.z,
        ]
      );

      const clipM = new AnimationClip("UpAndDown", -1, [positionKFM]);
      const clipO = new AnimationClip("UpAndDown", -1, [positionKFo]);
      const clipI = new AnimationClip("UpAndDown", -1, [positionKFi]);
      const clipN = new AnimationClip("UpAndDown", -1, [positionKFn]);
      const clipBang = new AnimationClip("UpAndDown", -1, [positionKFbang]);

      // Create an animation mixer and add the clip to it
      this.mixer = new AnimationMixer(this.letters[0]);
      const actionM = this.mixer.clipAction(clipM);
      actionM.play();

      this.mixerO = new AnimationMixer(this.letters[1]);
      const actionO = this.mixerO.clipAction(clipO);
      actionO.play();

      this.mixerI = new AnimationMixer(this.letters[2]);
      const actionI = this.mixerI.clipAction(clipI);
      actionI.play();

      this.mixerN = new AnimationMixer(this.letters[3]);
      const actionN = this.mixerN.clipAction(clipN);
      actionN.play();

      this.mixerBang = new AnimationMixer(this.letters[4]);
      const actionBang = this.mixerBang.clipAction(clipBang);
      actionBang.play();

      // lights
      var lightA = new DirectionalLight(0xffffff);
      lightA.position.set(10, 10, 10);
      this.scene.add(lightA);
      var lightB = new DirectionalLight(0xffffff);
      lightB.position.set(-5, -5, -5);
      this.scene.add(lightB);
      var lightC = new AmbientLight(0x222222);
      this.scene.add(lightC);

      var lightD = new DirectionalLight(0xffffff);
      lightD.position.set(-5, -5, 5);
      this.scene.add(lightD);

      /*  // Axis Line 1
      var materialB = new LineBasicMaterial({ color: 0x0000ff });
      var geometryB = new Geometry();
      geometryB.vertices.push(new Vector3(0, 0, 0));
      geometryB.vertices.push(new Vector3(0, 1000, 0));
      var lineA = new Line(geometryB, materialB);
      this.axisLines.push(lineA);
      // Axis Line 2
      var materialC = new LineBasicMaterial({ color: 0x00ff00 });
      var geometryC = new Geometry();
      geometryC.vertices.push(new Vector3(0, 0, 0));
      geometryC.vertices.push(new Vector3(1000, 0, 0));
      var lineB = new Line(geometryC, materialC);
      this.axisLines.push(lineB);
      // Axis 3
      var materialD = new LineBasicMaterial({ color: 0xff0000 });
      var geometryD = new Geometry();
      geometryD.vertices.push(new Vector3(0, 0, 0));
      geometryD.vertices.push(new Vector3(0, 0, 1000));
      var lineC = new Line(geometryD, materialD);
      this.axisLines.push(lineC);
      this.scene.add(...this.axisLines); */
    },
    RESIZE(width, height) {
      this.width = width;
      this.height = height;
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(width, height);
      this.postprocessing.composer.setSize(width, height);
      this.controls.handleResize();
      this.postprocessing.composer.render(0.1);
      //this.renderer.render(this.scene, this.camera);
    },

    INIT(width, height, el) {
      document.body.style.overflow = "hidden";
      this.SET_VIEWPORT_SIZE(width, height);
      this.INITIALIZE_RENDERER(el);
      this.INITIALIZE_CAMERA();
      this.INITIALIZE_CONTROLS();
      this.INITIALIZE_SCENE();
      this.INITIALIZE_POSTPROCESSING();

      //this.renderer.render(this.scene, this.camera);
      this.postprocessing.composer.render(0.01);
      this.controls.addEventListener("change", () => {
        this.postprocessing.composer.render(0.01);
        //this.renderer.render(this.scene, this.camera);
      });
    },

    handleMouseMove() {
      const targetX = (this.mouse.x - window.innerWidth / 2) * 0.1;
      const targetY = -(this.mouse.y - window.innerHeight / 2) * 0.1;

      const ease = 0.05;

      const deltaX = targetX - this.camera.position.x;
      const deltaY = targetY - this.camera.position.y;
      let target = new Vector3(0, 0, 0);

      setTimeout(() => {
        this.camera.position.x += deltaX * ease;
        this.camera.position.y += deltaY * ease;

        //this.camera.lookAt(0, 0, 0);
        this.controls.target = target;
      }, 50);
    },

    ANIMATE_CAMERA(acceleration = 2) {
      const startPosition = new Vector3(-150, -30, 0); // Starting position of the camera, offset to the left
      const endPosition = new Vector3(0, 0, 135); // Ending position of the camera
      const controlPoint = new Vector3(0, 0, 60); // Control point for bezier curve

      const cameraPosition = { t: 0 }; // Object to store tween progress

      const bezierTween = new TWEEN.Tween(cameraPosition) // Create a tween on the cameraPosition object
        .to({ t: 1 }, 4000) // Tween the t property from 0 to 1 over 4 seconds
        .easing(TWEEN.Easing.Cubic.InOut) // Use a cubic easing function for smooth animation
        .onUpdate(() => {
          // Update the camera position on each frame of the tween
          const easedT = Math.pow(cameraPosition.t, acceleration); // Apply acceleration to the percentage of time elapsed
          const curvePosition = new Vector3().lerpVectors(
            startPosition,
            controlPoint,
            cameraPosition.t
          ); // Calculate position along the bezier curve
          const currentPosition = new Vector3().lerpVectors(
            curvePosition,
            endPosition,
            easedT
          ); // Calculate current position based on the bezier curve and easing function
          this.camera.position.copy(currentPosition); // Update camera position
        });

      bezierTween.start(); // Start the tween
    },

    EXIT_CAMERA() {
      this.exiting = true;
      const startPosition = new Vector3(
        this.camera.position.x,
        this.camera.position.y,
        this.camera.position.z
      ); // Starting position of the camera
      const endPosition = new Vector3(-100, -100, -4000); // Ending position of the camera
      /* const startRotation = new Quaternion(); // Starting rotation of the camera
      const endRotation = new Quaternion(); // Ending rotation of the camera */
      const duration = 3000; // Time it takes to complete the animation in milliseconds
      this.controls.target = endPosition;
      //console.log(this.controls.target);

      // Create a new Tween object for the camera position
      const positionTween = new TWEEN.Tween(startPosition)
        .to(endPosition, duration)
        .easing(TWEEN.Easing.Cubic.InOut)
        .onUpdate(() => {
          // Update the camera position
          this.camera.position.copy(startPosition);
        });

      // Create a new Tween object to trigger the end of the animation
      const endTween = new TWEEN.Tween({}).to({}, 50);

      // Create a new Tween object for the camera rotation
      /*  const rotationTween = new TWEEN.Tween(startRotation)
        .to(endRotation, duration)
        .easing(TWEEN.Easing.Cubic.InOut)
        .onUpdate(() => {
          // Update the camera rotation
          this.camera.quaternion.copy(startRotation);
        }); */

      endTween.onUpdate(() => {
        // Once the animation is complete, remove the camera from the scene
        this.$store.commit("landingAnimationVisited");
        // document.querySelector(".curtain").style.display = "none";
        const curtain = document.querySelector(".curtain");
        if (curtain) {
          curtain.style.background = 'white';
          
          setTimeout(() => {
            curtain.style.opacity = 0;
            curtain.remove();
          }, 1000); // Wait for the transition to complete (0.5s) before removing the element
        }
        document.body.style.overflow = "";
        //this.$router.go();
        // this.$router.push({name : "Home"})
        this.scene.remove(this.letters);
        this.scene.remove(this.camera);
      });

      // Chain the tweens together and start the animation
      positionTween.chain(endTween).start();
    },

    ANIMATE() {
      window.requestAnimationFrame(this.ANIMATE);
      this.mixer.update(0.002);
      this.mixerO.update(0.001);
      this.mixerI.update(0.003);
      this.mixerN.update(0.002);
      this.mixerBang.update(0.004);
      //state.renderer.render(state.scene, state.camera);
      this.postprocessing.composer.render(0.1);
      //console.log(this.scene.position);
      TWEEN.update();

      // trigger exit if user zooms beyond a certain threshold

      /* let distance = this.camera.position.distanceTo(this.scene.center)
      if(distance < 100) {
        this.EXIT_CAMERA();
      } */

      let zero = new Vector3(0, 0, 0);
      let distance = this.camera.position.distanceTo(zero);
      if (distance < 50 && !this.exiting) {
        this.EXIT_CAMERA();
      }

      this.handleMouseMove();

      this.controls.update();
    },
  },
};
</script>
