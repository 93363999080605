<template>
  <div>
    <div
      class="close mobile-menu-toggle"
      @click="toggleMenu"
    >
      <img
        v-if="menu == false"
        src="~@/assets/menu_1pt.svg"
      >
      <img
        v-if="menu"
        src="~@/assets/cross.svg"
      >
    </div>
    <nav
      v-if="menu"
      class="mobile-menu"
    >
      <ul>
        <li @click="scrollInto('services')">
          Services
        </li>
        <li @click="scrollInto('offerings')">
          Angebotsgenerator
        </li>
        <li @click="scrollInto('team')">
          Team
        </li>
        <li @click="scrollInto('clients')">
          Clients
        </li>
        <li @click="scrollInto('about')">
          About
        </li>
        <li @click="scrollInto('contact')">
          Kontakt
        </li>
        <li @click="route('legal')">
          Impressum
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    menu() {
      return this.showMenu;
    },
    scrollLocked() {
      return this.$store.state.scrollLocked;
    },
  },
  methods: {
    toggleMenu() {
      console.log("toggle mobile menu");
      if (this.blocked) {
        this.blocked = false;
        const root = document.body;
        root.classList.remove("scrollblock");
      }
      this.showMenu = !this.showMenu;
    },
    scrollBlock() {
      const root = document.body;
      if (this.scrollLocked) {
        root.classList.remove("scrollblock");
        this.$store.commit("setScrollLock", false);
        console.log("blocked: " + this.scrollLocked);
      } else {
        root.classList.add("scrollblock");
        this.$store.commit("setScrollLock", true);
        console.log("blocked: " + this.scrollLocked);
      }
    },
    scrollInto(hash) {
      setTimeout(function () {
        let element = document.getElementById(hash);
        element.scrollIntoView({ behavior: "smooth", block: "start" });
        this.$store.commit("setVisibleChapterMain", hash + "-tab");
      }, 200);

      // dissolve offerings editor by clicking any other link:
      if (this.$store.state.generatorActive) {
        const root = document.body;
        root.classList.remove("scrollblock");
        this.$store.commit("setScrollLock", false);
        this.$store.commit("toggleGenerator");
        console.log("dissolve generator mobile");
      }
      this.toggleMenu();
    },
    route(path) {
      this.$router.push("/" + path);
    },
  },
};
</script>
