<template>
  <div class="info">
    <div class="info-column">
      <h3>Eure Seamless Writing Experience</h3>
      <h3>Info</h3>
      <p>
        Wählt euch eure Formate wie Blogartikel, Social Media Post oder passt
        eines unserer Pakete eurem Bedarf an. Entscheidet wieviele Einheiten
        eines Formats ihr benötigt, seht die Preise und welche Mengenrabatte ihr
        erhaltet. Stunden können frei für eure eigenen Formate, Grafiken und
        zusätzliche Aufwände eingesetzt werden. Ladet euch euer Festpreisangebot
        herunter oder kontaktiert uns direkt. Beispiel: Ihr wählt 4 x
        Blogartikel, 5 x Social Media Posts und 10 x Story Slides. Im oberen
        Bereich werden euch der Preis sowie euer Rabatt angezeigt. Ändert ihr
        die Anzahl bei den Formaten, werden Preis und Rabatt immer angepasst.
        Werden bei der Umsetzung doch mal andere Formate benötigt, werden diese
        mit gekauften Formaten verrechnet.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info",
  components: {},
  computed: {},
};
</script>
