<template>
  <div
    class="menu-header-tabed-container"
    :class="{ 'mobile-menu-close': generatorActive }"
  >
    <MobileMenu />
    <TheHeader />
    <MenuHeaderNarrow v-if="!generatorActive" />
    <div class="menu-header-tabed large">
      <nav class="menu-header-tabed-links">
        <a
          id="services-tab"
          class="tabed-menu-item-desktop"
          :class="{ selected: getActive === 'services-tab' }"
          @click="scrollInto('services')"
        >
          Services
        </a>
        <a
          id="offerings-tab"
          class="tabed-menu-item-desktop"
          :class="{ selected: getActive === 'offerings-tab' }"
          @click="scrollInto('offerings')"
        >
          Angebotsgenerator
        </a>
        <a
          id="team-tab"
          class="tabed-menu-item-desktop"
          :class="{ selected: getActive === 'team-tab' }"
          @click="scrollInto('team')"
        >
          Team
        </a>
        <a
          id="clients-tab"
          class="tabed-menu-item-desktop"
          :class="{ selected: getActive === 'clients-tab' }"
          @click="scrollInto('clients')"
        >
          Clients
        </a>
        <a
          id="about-tab"
          class="tabed-menu-item-desktop"
          :class="{ selected: getActive === 'about-tab' }"
          @click="scrollInto('about')"
        >
          About
        </a>
        <a
          id="contact-tab"
          class="tabed-menu-item-desktop"
          :class="{ selected: getActive === 'contact-tab' }"
          @click="scrollInto('contact')"
        >
          Kontakt
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
import TheHeader from "../TheHeader.vue";
import MobileMenu from "../MobileMenu.vue";
import MenuHeaderNarrow from "../content-page/MenuHeaderNarrow.vue";

export default {
  name: "MenuHeaderTabed",
  
  components: {
    TheHeader,
    MenuHeaderNarrow,
    MobileMenu,
  },
  data() {
    return {
      selectedId: "services-tab",
      expanded: false,
      blocked: false,
    };
  },
  computed: {
    getActive() {
      return this.$store.getters.getVisibleChapterMain;
    },
    generatorActive() {
      return this.$store.state.generatorActive;
    },
    scrollLocked() {
      return this.$store.state.scrollLocked;
    },
  },
  methods: {
    scrollInto(hash) {
      let element = document.getElementById(hash);
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      this.markSelected(hash + "-tab");
      this.$store.commit("setVisibleChapterMain", hash + "-tab");
      window.history.pushState("object or string", hash, "#" + hash);
      this.dissolveGenerator();
    },
    async dissolveGenerator() {
      if (this.generatorActive) {
        console.log("generator auflösen bitte");
        // toggle generator visibility
        this.$store.commit("toggleGenerator");
        // release scrollblock
        this.$store.commit("toggleScrollLock");
        this.scrollBlock();
        // reset generator parameters to default
        this.$store.commit("resetGeneratorDefaults");
      }
    },
    markSelected(hash) {
      // remove class from previously selected tab
      document.getElementById(this.selectedId).classList.remove("selected");
      this.selectedId = hash;

      document.getElementById(hash).classList.add("selected");

      console.log(this.selectedId);
    },
    scrollBlock() {
      const root = document.body;
      if (this.scrollLocked) {
        root.classList.remove("scrollblock");
        this.$store.commit("toggleScrollLock");
      } else {
        root.classList.add("scrollblock");
        this.$store.commit("toggleScrollLock");
      }
    },
  },
};
</script>
