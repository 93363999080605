<template>
  <div
    @mousemove="changePerspective"
    @ondeviceorientation="mobilePerspective"
    class="curtain"
  >
    <BackgroundAnimation :mousecoords="mousecoords" ref="backgroundAnimation" />
    <div id="landing" class="landing">
      <div
        class="landing-select"
        @mouseover="blurback(true)"
        @mouseleave="blurback(false)"
        @click="redirect()"
      >
        <img src="@/assets/Wirklichkeitsdichte-agentur-text-b2b-content-strategie.svg" 
            title="Wirklichkeitsdichte Logo" 
            alt="Wirklichkeitsdichte – Agentur für Text und B2B Content Strategien"/>
        <h3>wirklichkeitsdichte</h3>

        <div class="landing-blurb">
          Agentur für textbasierten Content <br />
          und wirklichkeitsstiftende <br />
          Maßnahmen
        </div>
        <img
          id="downarrow"
          class="landing-downarrow"
          src="@/assets/downarrow_1pt.svg"
          title="arrow down"
        />
      </div>
    </div>
    <!-- <CookieBanner :v-if="!$store.getters.cookiesAccepted" /> -->
  </div>
</template>

<script>
/* import CookieBanner from "../CookieBanner.vue"; */
import BackgroundAnimation from "../landing/BackgroundAnimation.vue";
export default {
  name: "Landing",
  components: {
    BackgroundAnimation,
   /*  CookieBanner, */
  },
  data() {
    return {
      mousecoords: {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      },
      opacity: 1,
      options: {
                    minimizable: false,
                    playerSize: "standard",
                    backgroundColor: '#FFFFFFFF',
                    backgroundStyle: 'none',
                    theme: {
                        controlsView: "standard",
                        active: "light",
                        light: {
                            color: '#3D4852',
                            
                            opacity: '0.7',
                        },
                        dark: {
                            color: '#fff',
                            opacity: '0.7',
                        }
                    }
                  },
    };
  },
  mounted() {
    window.onload = () => {
    console.log("!! ready !!");
  }
    this.repeatBounceClass(document.getElementById("downarrow")),
      window.addEventListener(
        "deviceorientation",
        (event) => {
          this.mobilePerspective(event.alpha, event.beta, event.gamma);
        },
        true
      ),
      window.addEventListener("touchmove", (event) => {
        this.changeOpacityMobile(event);
      });
  },
  methods: {
    redirect() {
      /* this.$store.commit("landingAnimationVisited");
      this.$router.go(); */
      this.$refs.backgroundAnimation.EXIT_CAMERA();
    },
    processLoad(event) {
      console.dir(event);
    },
    blurback(blur) {
      let animation = document.getElementById("landing");

      let downarrow = document.getElementById("downarrow");
      if (blur) {
        animation.classList.add("landing-animation-blur");

        downarrow.classList.add("arrow-bounce");
      }
      if (!blur) {
        animation.classList.remove("landing-animation-blur");

        downarrow.classList.remove("arrow-bounce");
      }
    },
    repeatBounceClass(element) {
      setInterval(function () {
        element.classList.add("arrow-bounce");
        setTimeout(function () {
          element.classList.remove("arrow-bounce");
        }, 2000);
      }, 4000);
    },
    changePerspective(event) {
      this.mousecoords.x = event.clientX;
      this.mousecoords.y = event.clientY;
    },
    mobilePerspective(alpha, beta, gamma) {
      let box = document.getElementById("landing").getBoundingClientRect();
      this.mousecoords.x = Math.min(
        Math.max(0, gamma / (box.width / 100)),
        100
      );
      this.mousecoords.y = Math.min(
        Math.max(0, beta / (box.height / 100)),
        100
      );
      // console.log("alpha: " + alpha);
      // console.log("gamma: " + gamma);
    },
    changeOpacity(event) {
      this.opacity = this.opacity - event.deltaY * 0.001;

      // Restrict values
      this.opacity = Math.min(Math.max(0.0, this.opacity), 1);

      if (this.opacity == 0) {
        this.redirect();
      }
    },
    changeOpacityMobile(event) {
      let box = document.getElementById("landing").getBoundingClientRect();
      // console.log(event.changedTouches[0].clientY);

      // console.log("opacity-touch: " + this.opacity);

      const touchPosition = event.changedTouches[0].clientY;

      const heightPercent = box.height / 100;

      // console.log("heightPercent: " + heightPercent);

      //console.log("differenz: " + (box.height - touchPosition));

      this.opacity = (box.height - touchPosition) * 0.1;

      if (touchPosition / heightPercent < 50) {
        //((console.log("klappt das?" + (touchPosition / heightPercent));
      }

      if (this.opacity < 0.3) {
        
        // this.redirect();
      }
    },
  },
};
</script>
