<template>
  <div class="profile-modal">
    <div class="profile-modal-gradient" />
    <img
      src="~@/assets/cross_white.svg"
      class="profile-modal-close"
      @click="$emit('closeProfileModal')"
    >
    <div class="profile-pic-modal">
      <img
        :src="require(`@/assets/team/${profiles.imgurl}`)"
        class="profile-pic"
      >
    </div>
    <div class="profile-text-modal">
      <h1 class="profile-name-modal">
        {{ profiles.name }}
      </h1>
      <div>
        <p>
          {{ profiles.description }}
        </p>
        <p><b>Job:</b> {{ profiles.job }}</p>
        <p><b>Berufung:</b> {{ profiles.calling }}</p>
      </div>
    </div>
    <a
      :href="'mailto:' + profiles.mail"
      class="profile-contact-button-modal"
    >Kontaktieren</a>
  </div>
</template>
<script>
export default {
  props: {
    profiles: Object,
  },
  methods: {},
};
</script>
